@import '../../styles/variables';

.breadcrumb {
  background-color: rgba($color: $black, $alpha: 0.5);
  padding: 10px 0px;
  width: 100%;
  z-index: 101;

  .breadcrumb-item {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;

    .breadcrumb-separator {
      color: $yellow;
      white-space: pre;
    }

    .breadcrumb-text {
      color: $yellow;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 16px;
    }

    &.active {
      .breadcrumb-link {
        cursor: text;
        pointer-events: none;
      }

      .breadcrumb-text {
        color: $white;
        font-weight: 400;
      }
    }
  }

  @media screen and (min-width: $md) {
    .container {
      max-width: calc(1360px);
    }
  }
}