.embed-item {
  .bg-dark {
    background-color: var(--black);
    color: var(--white);
  }

  .bg-gold {
    background-color: var(--gold);
    color: var(--white);
  }
}

.bg-gold {
  .embed-item {
    background-color: var(--gold);
    color: var(--white);
  }
}