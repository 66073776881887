@import '../../styles/variables';

.quote-wrapper {
  border-left: 2px solid $yellow;

  .title {
    font-weight: 500 !important;
    font-style: italic;
  }

  .source {
    font-weight: 400 !important;
    opacity: 60%;
  }
}

@media screen and (max-width: $xs) {
  .quote-wrapper {
    margin-left: 20px;
    margin-right: 0;
    margin-top: 40px;
    padding: 0 20px !important;
  }
}

@media screen and (min-width: $md) {
  .quote-wrapper {
    margin-left: 110px;
    margin-right: 0;
    margin-top: 40px;
    padding-left: 35px !important;
    max-width: 645px !important;
  }
}

.dark-mode {
  .title {
    color: $white;
  }

  .source {
    color: $lightGray;
  }
}

.light-mode {
  .title {
    color: $black;
  }

  .source {
    color: $gray;
  }
}
