@import '../../styles/_variables.scss';

.footer {
  background-color: $black2;

  .footer-wrapper {
    padding-top: 2rem;

    .logo {
      padding-left: 16px;
      width: 52px;
    }

    &.container {
      & > .rich-text.container {
        max-width: unset;
        padding: 0;
      }
    }

    .footer-top {
      border-bottom: solid 1px #3a3b39;
      padding: 36px 0;
    }

    .footer-main {
      padding: 24px 0;
    }

    .footer-left {
      padding: 0 8px;

      .heading {
        p,
        h1,
        h2,
        h3,
        h4,
        h5 {
          color: $yellow;
          font-size: 32px !important;
        }
      }
    }

    .footer-right {
      padding: 12px 24px 24px 24px;

      .form {
        h1,
        h2,
        h3,
        h4 {
          color: $white;
          font-size: 32px;
          text-align: left;
        }

        p {
          color: #fbf9f6;
          opacity: 50%;
          text-align: left;
        }

        .embed-item {
          padding-left: 0 !important;
        }

        .class-applied-to-form {
          margin-left: 0;
        }

        .input {
          > input {
            background-color: transparent;
            border: none;
            border-bottom: solid $gray 1px;
            border-radius: 0%;
            color: $white;

            &:focus {
              box-shadow: none;
            }
          }
        }

        .actions {
          > input {
            background-color: $black2;
            border-color: $gray;
            border-style: solid;
            border-width: 2px;
            color: $yellow;
            margin: 8px;

            &:hover {
              background-color: $yellow;
              border-style: solid;
              border-width: 2px;
              color: $black2;
            }
          }
        }

        .hs-richtext.hs-main-font-element {
          p {
            font-weight: 600;
          }
        }
      }

      .page-nav-links {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .page-nav-links-row {
          display: flex;
          flex-direction: column;
          padding: 8px 0;

          .page-link a {
            color: $white;
            font-weight: 600;
            padding: 4px;

            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }

    .page-nav-links,
    .social-media-links {
      display: flex;
      justify-content: center;
    }

    .social-media-links {
      margin-bottom: 60px;
      padding-top: 6px;

      .icon {
        font-size: 56px;
      }

      .social-link {
        margin-right: 14px;
        text-decoration: none;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .footer-lc-be-wrapper {
    background-color: rgba(0, 0, 0, 0.25);
    color: #888888;
    font-size: 14px;
    padding: 0;
    width: 100%;

    .be-ix-link-block {
      padding: 0;

      .be-related-link-container {
        align-items: baseline;
        display: block;
        justify-content: center;
        margin-bottom: 0;
        padding: 16px 0 10px 0;
        text-align: center;

        .be-label {
          color: #888;
          display: block;
          margin-bottom: 10px;
          margin-right: 0;
          flex-grow: 0;
          flex-shrink: 0;
        }

        .be-list {
          display: block;
          list-style: none;
          margin: 0;
          padding: 0;

          .be-list-item {
            display: block;
            margin-bottom: 6px;
            margin-right: 0;

            .be-related-link {
              color: $yellow;
            }
          }
        }
      }
    }
  }

  .footer-banner {
    background-color: $gray;
    width: 100%;

    .container {
      display: flex;
      flex-direction: column;
      column-gap: 32px;
      row-gap: 16px;
      justify-content: center;
      padding: 20px 16px;

      > div {
        align-items: center;
        color: #888888;
        display: inline-flex;
        flex-wrap: wrap;
        font-weight: 600;
        justify-content: center;
        margin: 0px;
        text-align: center;
        white-space: pre;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .footer {
    .footer-top {
      border-bottom: 1px solid #333333;
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .logo {
        width: 52px;
      }

      .socials {
        justify-content: flex-end;

        .social-media-links {
          margin-bottom: 0px;
        }
      }
    }

    .footer-main {
      display: grid;
      gap: 100px;
      grid-template-columns: 50% 50%;
      text-align: left;

      .footer-left {
        padding: 24px;

        .heading {
          p,
          h1,
          h2,
          h3,
          h4,
          h5 {
            color: $yellow;
            font-size: 52px !important;
          }
        }
      }

      .footer-right {
        max-width: 540px;
        padding: 0px 24px 24px 0px;
        width: calc(100% - 120px);

        .form {
          margin: auto;

          h1,
          h2,
          h3,
          h4 {
            color: $white;
            font-size: 32px;
            text-align: left;
          }

          p {
            color: #fbf9f6;
            opacity: 50%;
            text-align: left;
          }

          .embed-item {
            max-width: 612px !important;
            padding-right: 0px !important;

            .class-applied-to-form {
              margin-right: 0px;
              max-width: 612px !important;
            }

            .input {
              > input {
                background-color: transparent;
                border: none;
                border-bottom: solid $gray 1px;
                border-radius: 0%;
                color: $white;

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .actions {
              > input {
                background-color: $black2;
                border-color: $gray;
                border-width: 2px;
                color: $yellow;
                margin: 8px;

                &:hover {
                  background-color: $yellow;
                  border-style: solid;
                  border-width: 2px;
                  color: $black2;
                }
              }
            }
          }
        }

        .page-nav-links {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .page-nav-links-row {
            display: flex;
            flex-direction: column;

            .page-link a {
              color: $white;
              padding: 4px;

              &:hover {
                color: $yellow;
              }
            }
          }
        }
      }
    }

    .footer-lc-be-wrapper {
      .be-ix-link-block {
        padding: 0;

        .be-related-link-container {
          padding: 16px 0;
          display: flex;

          .be-label {
            display: inline-block;
            margin-bottom: 0px;
            margin-right: 20px;
            flex-grow: 0;
            flex-shrink: 0;
          }

          .be-list {
            display: inline-block;
            list-style: none;
            margin: 0;
            padding: 0;

            .be-list-item {
              display: inline-block;
              margin-bottom: 0;
              margin-right: 20px;

              .be-related-link {
                color: $yellow;
              }
            }
          }
        }
      }
    }

    .footer-banner {
      min-height: 50px;

      .container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px 0px;
        row-gap: 12px;

        > div {
          align-items: center;
          color: #888888;
          display: inline-flex;
          flex-grow: 0;
          font-weight: 600;
          text-align: center;
          white-space: pre;
          width: auto;
        }
      }
    }
  }
}
