@import '../../styles/variables';

.carousel-wrapper {
  max-width: $contentMaxWidth;
  margin: 0 auto;
  padding: 20px 16px;
  .header-container {
    display: flex;
    border-bottom: 1px solid #ffffff2b;
    .title {
      font-weight: 500;
      color: $yellow;
      margin: auto 0;
    }
    .all-button-container {
      display: flex;
      margin: auto 0 auto auto;
      &:hover {
        svg {
          path.path2 {
            fill: $yellow;
          }
        }
      }
      .text {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin: auto 0 auto auto;
      }
      .icon {
        width: 32px;
        height: 32px;
        margin: auto 0 auto 10px;
      }
    }
  }
  .contents {
    cursor: pointer;
    height: 350px;
    overflow-x: auto;
    padding: 16px 0;
    white-space: nowrap;
  }
  .contents:has(> :nth-child(5)) {
    cursor: pointer;
  }
  .contents::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: $md) {
  .carousel-wrapper {
    .header-container {
      margin-bottom: 40px;
      padding-bottom: 10px;
      .title {
        font-size: 25px;
        line-height: 18px;
      }
    }
    &.big-cards {
      .contents {
        height: 420px;
        width: 100%;
        padding: 20px 16px 40px 16px;
        margin-top: -15px;
        overflow-y: hidden;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .carousel-wrapper {
    .header-container {
      margin-bottom: 50px;
      .title {
        font-size: 42px;
        line-height: 67px;
      }
    }
    &.big-cards {
      .contents {
        height: 600px;
        padding: 40px 0 40px 40px;
        margin: -60px 0 0 -60px;
      }
    }
    .contents {
      height: 350px;
      padding: 20px 0px;
    }
  }
}

.dark-mode {
  .all-button-container {
    .text {
      color: $white;
    }
  }
}

.light-mode {
  .all-button-container {
    .text {
      color: $gray;
    }
  }
}
