@font-face {
  font-family: 'editors_note';
  src: url('../fonts/editors-note/Editor\'sNote-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'editors_note';
  src: url('../fonts/editors-note/Editor\'sNote-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'editors_note';
  src: url('../fonts/editors-note/Editor\'sNote-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'editors_note';
  src: url('../fonts/editors-note/Editor\'sNote-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'editors_note';
  src: url('../fonts/editors-note/Editor\'sNote-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-thin-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-thin-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-thin-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-light-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-light-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-regular-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-regular-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-semibold-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-semibold-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-bold-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-bold-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'proxima_nova';
    src: url('https://interactivefonts.life.church/proximanova-black-webfont.woff2') format('woff2'),
         url('https://interactivefonts.life.church/proximanova-black-webfont.woff') format('woff'),
         url('https://interactivefonts.life.church/proximanova-black-webfont.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }