@import '../../styles/_variables.scss';

.sidebar-wrapper{
    @media screen and (min-width: $lg){

            display: grid;
            grid-template-columns: calc(100% - 450px) 450px;
            max-width: $contentMaxWidth;
            margin: auto;
            .sidebar-mainarea{
                padding: 0px 36px 0 24px;
            }
            .sidebar-sidebar{
                padding: 0px 36px 0 24px
            }
        
    }
}




