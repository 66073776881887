@import '../../styles/variables';

.next-steps-wrapper {
  max-width: 415px !important;

  .next-steps-title {
    border-bottom: 1px solid $white;
    color: $white;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 20px 0;
    padding-bottom: 20px;
  }

  .next-steps-contents {
    display: flex;
    flex-wrap: wrap;

    // Backwards-looking class names for direction, but column layout uses
    // default flex-direction of row, whereas row layout uses flex-direction of
    // column to stack content.
    &.column {
      flex-direction: row;
    }

    &.row {
      flex-direction: column;
    }

    .next-steps-column {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      width: 100%;

      .next-steps-text {
        flex: 1;
        margin-right: auto;

        p {
          color: $yellow;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 17px;
          margin: 0 !important;
          text-decoration: underline;
        }
      }

      .icon {
        display: inline-block;
        height: 35px;
        padding-left: 10px;
        width: 35px;
      }

      .next-steps-subtitle-wrapper {
        border-right: 2px solid $white;
        padding-right: 30px;

        .next-steps-small-subtitle {
          color: #a48d45;
          font-size: 14px;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 0.2em;
          margin: 0;
          text-transform: uppercase;
        }

        .next-steps-big-subtitle {
          color: $white;
          font-size: 25px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 30px;
          margin: 0;
          text-transform: uppercase;
        }
      }
    }

    .next-steps-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .big-subtitle {
        color: $white;
        font-size: 36px;
        font-weight: 600;
        line-height: 46.69px;
        margin: 0 0 8px 0;
      }

      .small-subtitle {
        color: #a48d45;
        font-size: 14px;
        letter-spacing: 0.2em;
        line-height: 17.05px;
        margin: 0;
        text-transform: uppercase;
      }

      .link {
        display: flex;
        margin-bottom: 8px;
        width: 100%;

        a {
          font-size: 14px;
          text-decoration: underline;
        }

        .icon {
          height: 35px;
          margin: 0 24px;
          width: 35px;
        }
      }

      hr {
        background-color: $white;
        border: 0;
        height: 1.5px;
        margin: 0;
        width: 61px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .next-steps-wrapper {
    padding: 20px;

    .next-steps-subtitle-wrapper {
      margin-right: 20px;
      width: 100px;
    }
  }
}

@media screen and (min-width: $md) {
  .next-steps-wrapper {
    padding: 20px 40px !important;

    .next-steps-subtitle-wrapper {
      margin-right: 30px;
      width: 100px;
    }
  }
}

.next-steps-wrapper.light-mode {
  .next-steps-title {
    border-color: $black;
    color: #a48d45;
  }

  .next-steps-contents .next-steps-column {
    .next-steps-text p {
      color: #a48d45;
    }

    .next-steps-subtitle-wrapper {
      border-color: black;

      .next-steps-big-subtitle {
        color: $black;
      }
    }
  }

  .next-steps-row {
    .big-subtitle {
      color: $black;
    }

    .link {
      a {
        color: #a48d45;
      }
    }

    hr {
      background-color: $black;
    }
  }
}
