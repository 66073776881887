@import '../../styles/variables';

.featured-articles-wrapper {
  display: flow-root;
  margin: 0 auto;
  max-width: $contentMaxWidth;
  padding: 0 16px;

  .flex-content {
    display: flex;
    border-bottom: solid 1px #ffffff2b;
    margin: 24px 20px;

    .title {
      color: $yellow;
      font-weight: 500;
    }

    .all-button-container {
      display: flex;
      margin: auto 0 auto auto;

      &:hover {
        svg {
          path.path2 {
            fill: $yellow;
          }
        }
      }

      .text {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 17px;
        margin: auto 0 auto auto;
        text-transform: uppercase;
      }

      .icon {
        height: 32px;
        margin: auto 0 auto 10px;
        width: 32px;
      }
    }
  }

  .contents {
    display: grid !important;
    gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: $md) {
  .featured-articles-wrapper {
    .flex-content {
      display: flex;
      margin: 40px 0px 30px 0px;
      padding-bottom: 30px;

      .title {
        font-size: 25px;
        line-height: 18px;
      }

      .right-arrow {
        display: block;
        height: 32px;
        margin: auto 0 auto auto;
        width: 32px;
      }
    }

    .contents {
      display: grid;
      gap: 0;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      padding: 0px;
    }
  }
}

@media screen and (min-width: $md) {
  .featured-articles-wrapper {
    .flex-content {
      display: flex;
      margin: 45px 0px 50px 0px;
      padding: 30px 0px 5px 0px;

      .title {
        font-size: 42px;
        line-height: 67px;
        margin: 0 0 12px 0;
      }

      .right-arrow {
        display: block;
        height: 32px;
        margin: 25px 0 0 auto;
        width: 32px;
      }
    }

    .contents {
      display: grid;
      gap: 0;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding: 0px;
    }
  }
}
