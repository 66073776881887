.install-app-wrapper {
  width: 450px;
  padding: 20px 40px;
  .install-app-title {
    color: #fff;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0em;
    margin-top: 0;
    .yellow {
      color: #e6df69;
    }
  }

  .install-app-image-wrapper {
    display: flex;
    flex-direction: row;
    .apple {
      width: 145px;
      margin-right: 10px;
    }
    .google {
      width: 160px;
    }
  }
}
