@import '../../styles/variables';

.journey-listing-hero-wrapper {
  min-height: 500px;
  position: relative;
  width: 100%;

  .background-gradient {
    background: white;
    background: linear-gradient(180deg, rgba(73, 69, 56, 0) 0%, #494538 100%);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .background-image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .breadcrumb {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    top: 0;

    .yellow {
      color: $yellow;
      font-weight: 700;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    z-index: 100;

    .content {
      .title {
        font-weight: 500;
        letter-spacing: 0;
      }

      .featured-heading {
        font-weight: 500;
        letter-spacing: 0em;
      }

      .video-with-parts-wrapper {
        background: none;
      }

      .left-content {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .journey-listing-hero-wrapper {
    margin-bottom: 30px;

    .background-wrapper,
    .background-gradient,
    .background-image {
      height: 300px;
    }

    .container {
      margin: 0 auto;
    }

    .content-wrapper {
      margin-top: -300px;
    }

    .content {
      margin-top: auto;
      padding: 140px 0 15px 0;

      .title {
        font-size: 32px;
        line-height: 44px;
      }

      .featured-heading {
        font-size: 32px;
        line-height: 40px;
        margin-top: 60px;
      }

      .video-with-parts-wrapper {
        margin-bottom: 20px;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .journey-listing-hero-wrapper {
    .background-wrapper,
    .background-gradient,
    .background-image {
      height: 755px;
    }

    .content-wrapper {
      margin-top: -750px;
    }

    .content {
      margin-top: 150px;

      .title {
        font-size: 72px;
        line-height: 92px;
        padding-left: 10px;
      }

      .featured-heading {
        font-size: 52px;
        line-height: 67px;
        margin-top: 80px;
      }

      .video-with-parts-wrapper {
        margin-bottom: 100px;
        margin-top: -20px;
        padding: 0;
      }
    }
  }
}

.dark-mode {
  .title {
    color: $white;
  }

  .featured-heading {
    color: $yellow;
  }
}

.light-mode {
  .title {
    color: $gray;
  }

  .featured-heading {
    color: $yellow;
  }
}
