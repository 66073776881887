@import '../../styles/variables';

.article-page-hero-wrapper {
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
  width: 100%;

  .main-content-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
  }

  .flex-content {
    display: flex;
    margin: 0;
  }

  .background-gradient {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .background-image {
    display: block;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .breadcrumb {
    color: $white;
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    top: 0;

    .yellow {
      color: $yellow;
      font-weight: 700;
    }
  }

  .content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 100%;
    margin: auto;
    margin-top: 160px;
    position: relative;
    width: 100%;
    z-index: 3;

    .bottom-content {
      padding-bottom: 16px;

      .tag-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;

        .tag {
          background-color: #494538;
          color: $yellow;
        }
      }

      .title {
        font-weight: 500;
        letter-spacing: 0;
        margin: 10px 0 15px 0;
        max-width: 992px;
      }

      .author-duration {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 17px;
        margin-bottom: 0;
        opacity: 0.6;
      }
    }
  }

  &.no-background {
    .content-wrapper {
      margin-top: 80px;
    }
  }
}

@media screen and (max-width: $md) {
  .article-page-hero-wrapper {
    .main-content-wrapper {
      max-height: 432px;
    }

    .flex-content {
      flex-direction: column;
    }

    .social-media-links {
      justify-content: flex-start;
    }

    .background-gradient {
      height: 100%;
    }

    .background-image {
      height: 100%;
    }

    .content-wrapper {
      padding: 20px 0px 0px 0px;
    }

    .bottom-content {
      margin-top: auto;

      .title {
        font-size: 32px;
        line-height: 44px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .article-page-hero-wrapper {
    .main-content-wrapper {
      max-height: unset;
    }

    .flex-content {
      flex-direction: row;
      margin-top: 36px;
    }

    .social-media-links {
      justify-content: flex-end;
    }

    .background-gradient {
      height: 100%;
    }

    .background-image {
      height: 100%;
    }

    .content-wrapper {
      gap: 150px;
      padding: 20px;
    }

    .bottom-content {
      margin-top: auto;

      .title {
        font-size: 62px;
        line-height: 75px;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .article-page-hero-wrapper {
    .main-content-wrapper {
      max-height: unset;
    }
  }
}

.article-page-hero-wrapper.gradient-dark {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $black2);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}

.article-page-hero-wrapper.gradient-cream {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $cream);
  }

  .title {
    color: $black !important;
  }

  .author-duration {
    color: $gray;
  }
}

.article-page-hero-wrapper.gradient-gold {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gold);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}

.article-page-hero-wrapper.gradient-gray {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gray);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}
