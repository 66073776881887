@import '../../styles/variables';

.video-with-parts-wrapper {
  width: 100%;

  &.dark-mode {
    .title {
      color: $white;

      &:hover {
        color: $yellow;
      }
    }

    .right-content .icon-text-wrapper {
      .text {
        color: $yellow;
      }

      .icon-wrapper {
        .icon {
          border: 1px solid $gray;
          border-radius: 100%;
        }

        .line {
          background-color: $gray;
        }
      }
    }
  }

  &.light-mode {
    .title {
      color: $gray;

      &:hover {
        color: $yellow;
      }
    }

    .right-content .icon-text-wrapper {
      .text {
        color: $gold2;
      }

      .icon-wrapper .line {
        background-color: $black2;
      }
    }
  }

  &.bg-gold.light-mode {
    .title {
      color: $yellow;

      &:hover {
        color: $white;
      }
    }
  }

  .video-with-parts-container {
    display: flex;
    margin: 0 auto;
    max-width: $contentMaxWidth;

    .left-content {
      flex: 2;

      .video-wrapper {
        background: #333;
        border-radius: 7px;
        display: flex;

        .watch-video-button {
          background-color: #fff;
          border-radius: 100px;
          color: $black2;
          display: flex;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.2em;
          line-height: 17px;
          margin: auto;
          padding: 10px 20px;
          text-transform: uppercase;

          .play-icon {
            height: 13px;
            margin: auto 10px auto 0;
            width: 13px;
          }
        }
      }
    }

    .right-content {
      flex: 1;
      width: 100%;

      .title {
        font-weight: 500;
      }

      .parts-tag {
        background-color: #100f0e;
        border-radius: 50px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 2px 15px;
        text-align: center;
      }

      .splitter {
        border-color: #fff;
        margin: 30px auto;
        opacity: 0.2;
      }

      .start-button {
        border: none;
        margin-top: 35px;
        width: fit-content;
      }

      .icon-text-wrapper {
        display: flex;
        flex-direction: row;

        .icon-wrapper {
          display: flex;
          flex-direction: column;

          .icon {
            height: 30px;
            margin: auto 0 auto 0;
            width: 30px;
          }

          .line {
            border-radius: 40px;
            height: 17px;
            margin: 3px auto 0 auto;
            width: 2px;
          }
        }

        .text {
          color: #e6df69;
          font-size: 15px;
          font-weight: 600;
          line-height: 17px;
          margin: 5px 0 0 20px;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .video-with-parts-wrapper {
    padding: 50px 0;

    .video-with-parts-container {
      flex-direction: column;

      .right-content {
        .title {
          font-size: 22px;
          line-height: 28px;
          margin-top: 0;
        }
      }

      .left-content {
        margin-bottom: 20px;
        width: 100%;

        .video-wrapper {
          padding-bottom: calc(56.25% / 2);
          padding-top: calc(56.25% / 2);
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .video-with-parts-wrapper {
    padding: 75px 0;

    .video-with-parts-container {
      flex-direction: row;

      .right-content {
        max-width: 400px;

        .title {
          font-size: 32px;
          font-weight: 500;
          line-height: 40px;
          margin-bottom: 15px;
          max-width: 340px;
          padding-left: 0px !important;
        }
      }

      .left-content {
        margin: auto 90px auto 0;
        max-width: 800px;
        width: 100%;

        .video-wrapper {
          max-height: 431px;
          max-width: 796px;
          padding-bottom: calc(56.25% / 2);
          padding-top: calc(56.25% / 2);
          width: 100%;
        }
      }
    }
  }
}
