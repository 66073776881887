@import '../../styles/variables';

.filter-select-wrapper {
  align-items: center;
  align-self: flex-start;
  display: flex;
  font-size: 14px;
  position: relative;

  .filter-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer !important;
    padding-right: 20px;
    text-align: right;
    width: min-content;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 52%;
    transform: translateY(-50%);
    width: 10px;
  }

  /* For Firefox */
  .filter-select::-moz-focus-inner {
    border: 0;
  }

  /* For IE and Edge */
  .filter-select::-ms-expand {
    display: none;
  }

  .filter-select:focus-visible {
    border: none;
    outline: none;
  }

  &.dark {
    color: $white;
    opacity: 60%;

    svg {
      color: $yellow;
    }
  }

  &.light {
    color: $gray;
    opacity: 60%;

    svg {
      color: $gold2;
    }
  }
}