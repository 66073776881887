@import '../../styles/variables';

.image-with-parts-wrapper {
  .image-with-parts-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $yellow;
      border-radius: 7px;
      border: 5px solid $yellow;
      .start-button {
        display: none;
        max-width: fit-content;
      }
    }

    .card-main-link {
      inset: 0;
      position: absolute;
      z-index: 1;
    }

    .image-container {
      width: 100%;
      height: 100%;
      background-color: #555;
      border-radius: 7px;
      position: relative;
      .gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        background: #3d383466;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 7px;
      }
    }
    .icons-container {
      position: absolute;
      display: flex;
      flex-direction: row;
      .icon {
        width: 48px;
        height: 48px;
      }
    }
  }
  .bottom-content {
    position: absolute;
    margin-top: auto;
    .tag-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      row-gap: 8px;
      z-index: 2;
    }
    .tag {
      background-color: #494538;
      color: $yellow;
    }
    .title {
      color: $white;
      font-weight: 500;
      margin: 20px 0 20px 0;
    }
    .parts-tag {
      background-color: #100f0e;
      color: $white;
      border-radius: 50px;
      padding: 4px 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
  }
  .start-button {
    display: none;
    margin: 30px 0 10px 0;
  }

  &:not(.listing) {
    cursor: pointer;
  }
}

@media screen and (max-width: $md) {
  .image-with-parts-wrapper {
    cursor: pointer;

    .zoom-effect {
      &:hover {
        transform: none;
        border: none;
      }
    }
    .image-with-parts-content {
      width: 100%;
      min-height: 260px;
      height: 300px;
      .icons-container {
        top: 20px;
        left: 20px;
        .icon {
          margin-right: 25px;
        }
      }
    }
    .bottom-content {
      max-width: 240px;
      bottom: 35px;
      left: 20px;
      .title {
        font-size: 22px;
        line-height: 28px;
      }
    }
    .start-button {
      display: none;
    }
  }
}

@media screen and (min-width: $md) {
  .image-with-parts-wrapper {
    max-width: 787px;
    margin: auto;
    .image-with-parts-content {
      width: 100%;
      height: 500px;
      border: 5px solid transparent;
      .icons-container {
        top: 35px;
        left: 35px;
        .icon {
          margin-right: 15px;
        }
      }
    }
    .bottom-content {
      max-width: 300px;
      bottom: 35px;
      left: 35px;
      .title {
        font-size: 32px;
        line-height: 35px;
      }
    }
  }
  .listing {
    .start-button {
      display: none;
      margin: 30px 0 10px 0;
    }
    .image-with-parts-content:hover {
      background-color: $yellow;
      border-radius: 7px;
      border: 5px solid $yellow;
      transition: all 0.3s ease-in-out;
      z-index: 90;

      .start-button {
        display: flex;
        max-width: fit-content;
        position: relative;
        transition: all 0.3s ease-in-out;
        z-index: 91;
      }
    }
  }
}
