@import '../../styles/variables';

.image-with-subtitles-wrapper {
  display: flex;
  margin: 0 auto;
  max-width: $contentMaxWidth;

  &.dark-mode {
    .main-content {
      .title {
        color: $yellow;
      }

      .subtitle {
        color: $white;
      }

      .author-duration {
        color: $lightGray;
        opacity: 0.6;
      }

      .description {
        color: $lightGray;
        opacity: 0.6;
      }
    }
  }

  &.light-mode {
    .main-content {
      .tag-wrapper .tag {
        background-color: #f0edc0;
        color: $gray;
      }

      .title {
        color: $gray;
      }

      .subtitle {
        color: $gray;
      }

      .author-duration {
        color: $gray;
        opacity: 0.6;
      }

      .description {
        color: #3d3834;
      }
    }
  }

  &.bg-gold {
    .main-content {
      .tag {
        background-color: $gray;
      }
    }
  }

  .image-title-ref:hover,
  .subtitle-ref:hover {
    color: $yellow;

    > h3 {
      color: $yellow !important;
    }
  }

  .featured-content {
    .image-content-wrapper {
      background-color: $black;
      border-radius: 7px;
      cursor: pointer;
      position: relative;

      .image-gradient {
        background: #3d383466;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 69.58%
        );
        border-radius: 7px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .image {
        border-radius: 7px;
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      .image-content {
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        padding: 25px 40px;
        position: absolute;

        .tag-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 8px;

          .tag {
            background-color: #49453893;
            color: $yellow;
          }
        }

        .image-title {
          color: $white;
          font-weight: 500;
          margin: 10px 0;
        }

        .image-author-duration {
          color: $lightGray;
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          opacity: 0.6;
        }
      }
    }
  }
  .main-content {
    .flex-content {
      display: flex;

      .title {
        font-weight: 500;
      }

      .link-content {
        display: flex;
        margin: 25px 0 0 auto;

        .link-label {
          height: min-content;
          margin: 0 8px;
        }

        .right-arrow {
          display: block;
          height: 25px;
          width: 25px;
        }
      }
    }

    .subtitle {
      font-weight: 500;
      margin: 10px 0;
    }

    .description {
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 0;
    }

    .author-duration {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      opacity: 0.6;
    }

    .tag-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 8px;
    }

    .splitter {
      border-color: $gray;
      margin: 30px auto;
      opacity: 0.2;
    }

    .article-wrapper:last-child .splitter:last-child {
      display: none !important;
    }
  }
}

@media screen and (max-width: $md) {
  .image-with-subtitles-wrapper {
    padding: 20px;
    flex-direction: column;

    .featured-content {
      width: 100%;

      .image-content-wrapper {
        background-color: $black;
        min-height: 320px;
        position: relative;
        width: 100%;

        .image {
          min-height: 320px;
        }

        .image-content {
          padding: 10px 25px;

          .image-title {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }

    .main-content {
      .flex-content {
        display: flex;
        padding-top: 15px;

        .title {
          font-size: 35px;
          line-height: 40px;
        }

        .link-content {
          margin: auto 0 auto auto;

          .link-label {
            height: min-content;
            margin: auto 8px;
          }

          .right-arrow {
            display: block;
            height: 32px;
            width: 32px;
          }
        }
      }

      .subtitle {
        font-size: 25px;
        line-height: 25px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .image-with-subtitles-wrapper {
    margin-bottom: 40px;
    padding: 18px;

    .featured-content {
      margin-right: 100px;

      .image-content-wrapper {
        background-color: $black;
        height: 100%;
        position: relative;

        .image-content {
          padding: 25px 40px;

          .image-title {
            font-size: 52px;
            line-height: 67px;
          }
        }
      }
    }

    .main-content {
      .flex-content {
        display: flex;

        .title {
          font-size: 52px;
          line-height: 67px;
          margin: 0 0 45px 0;
        }
      }

      .subtitle {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .image-with-subtitles-wrapper {
    .featured-content {
      margin-right: 50px;
    }
  }
}

@media screen and (min-width: $xl) {
  .image-with-subtitles-wrapper {
    .featured-content {
      margin-right: 100px;
    }
  }
}
