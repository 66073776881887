@import '../../styles/_variables.scss';

.single-contentzone {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow: hidden;
  width: 100%;

  .video-background {
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .youtube-video-wrapper {
      height: 100%;
    }
  }

  &.mt-56 {
    margin-top: 56px;
  }

  &.height-70vh {
    height: 70vh;
  }

  &.rounded-corners {
    border-radius: var(--radius-s);
  }

  .singleIcon {
    border-radius: 100%;
    height: 86px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 86px;

    &.p-10 {
      padding: 10px;
    }

    img {
      border-radius: 100%;
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }

  > .container {
    box-sizing: border-box;
    display: flex;
    max-width: 1400px;
    width: 100%;

    &.content-right {
      justify-content: flex-end;
    }

    @media screen and (min-width: $md) {
      &.full-width {
        > div {
          width: 100%;
        }
      }

      &.twothirds {
        > div {
          width: calc((100% / 3) * 2);
        }
      }
    }

    > div {
      width: 100%;

      .container {
        padding: 0;
      }

      @media screen and (min-width: $md) {
        width: 50%;
      }
    }
  }

  .left-align {
    a:first-child {
      margin-left: 0;
    }
  }

  .right-align {
    a:last-child {
      margin-right: 0;
    }
  }
}
