@import '../../styles/variables';

.article-navigation {
  display: flex;
  font-family: var(--font-family-editor);
  justify-content: space-between;
  width: 100%;

  &.border-top {
    border-top: 1px solid #fffffe2e;
    padding-top: 15px;
  }

  &.border-bottom {
    border-bottom: 1px solid #fffffe2e;
    padding-bottom: 15px;
  }

  .back-link,
  .next-link {
    color: $yellow;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .back-link {
    align-items: flex-start;
    padding-right: 40px;
  }

  .next-link {
    align-items: flex-end;
    padding-left: 40px;
  }

  .back-icon,
  .next-icon {
    height: 32px;
    width: 32px;
  }

  .back-text,
  .next-text {
    font-size: 18px;
  }

  .back-text {
    text-align: left;
  }

  .next-text {
    text-align: right;
  }
}

@media screen and (min-width: $md) {
  .article-navigation {
    .back-icon,
    .next-icon {
      height: 25px;
      width: 25px;
    }

    .back-link,
    .next-link {
      align-items: center;
    }

    .back-link {
      flex-direction: row;
    }
    .next-link {
      flex-direction: row-reverse;
    }

    .back-text,
    .next-text {
      font-size: 25px;
      max-width: 370px;
    }
  }
}
