@import '../../styles/_variables.scss';

.header {
  background-color: $black2;

  .navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;

    .nav-desktop {
      display: none;
    }

    .menu-nav {
      align-items: center;
      column-gap: 15px;
      display: flex;
      text-transform: uppercase;

      .search {
        align-items: center;
        display: flex;
        width: 35px;
      }

      .hamburger-menu {
        align-items: center;
        color: $white;
        display: flex;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        > span {
          color: $white;
          display: none;
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          > span {
            color: $yellow;
          }
        }

        > .hamburger {
          display: flex;
          flex-direction: column;
          margin-right: 3px;
          min-height: 16px;
          min-width: 26px;
          position: relative;

          span {
            background-color: $yellow;
            height: 2px;
            position: absolute;
            top: 0;
            transition: transform 0.4s, top 0.2s ease-in;
            width: 26px;
          }

          span:nth-child(2) {
            top: 7px;
          }

          span:nth-child(3) {
            top: 14px;
          }

          &.active {
            span:nth-child(1),
            span:nth-child(2) {
              top: 5px;
              transform: rotate(45deg);
            }

            span:nth-child(3) {
              top: 5px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .header {
    .navigation {
      max-width: calc(1360px); // 1400px - 20px side padding.

      .menu-nav {
        .hamburger-menu {
          > img {
            margin-right: var(--spacing-normal);
          }

          > span {
            display: unset;
            font-family: var(--font-family);
            font-size: var(--f-small);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .header {
    .navigation {
      .menu-nav {
        display: unset;

        .search {
          width: 40px;
        }

        .hamburger-menu {
          display: none;
        }
      }

      .nav-desktop {
        display: unset;
      }

      .main-nav {
        align-items: center;
        display: flex;

        &.active {
          > li {
            color: $yellow;

            a {
              color: $yellow;
            }
          }
        }

        > li.nav-desktop {
          color: $white;
          margin-right: var(--spacing-normal);
          padding: 0px 12px;
          position: relative;
          vertical-align: middle;

          &:hover {
            > a {
              color: $yellow;
              text-decoration: underline;
            }

            .sub-nav {
              background-color: $black2;
              border-radius: 0% 0% 5% 5%;
              color: $white;
              display: block;
              padding: 20px 8px 20px 8px;
              position: absolute;
              right: -4px;
              text-decoration: none;
              width: 100%;
              z-index: 200;

              > li.nav-desktop {
                text-align: center;

                &:hover > a {
                  color: $yellow;
                  text-decoration: underline;
                }

                > a > span {
                  margin: auto;
                  padding: 4px 0px;
                }
              }
            }
          }

          a {
            color: $white;
            display: flex;
            font-size: var(--f-small);
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;
            text-transform: uppercase;

            .down-arrow {
              width: 24px;
            }
          }

          .sub-nav {
            display: none;
          }
        }
      }
    }
  }
}
