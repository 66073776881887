@import '../../styles/variables';

.topics-listing-wrapper {
  margin: 0 auto;
  max-width: $contentMaxWidth;
  overflow-x: hidden;

  .filters {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 30px;

    .left-filters {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 14px;
      justify-content: space-between;
      text-transform: capitalize;

      .search-icon {
        cursor: pointer;
        display: inline-block;
        height: 17px;
        margin: auto 20px auto 0;
        padding-bottom: 5px;
        width: 17px;
      }
    }
  }

  .listing-title {
    color: #a48d45;
    font-weight: 500;
  }

  .topic-title {
    color: white;
    font-weight: 500;
    padding-bottom: 100px;
  }

  .contents {
    .image-with-parts-wrapper {
      cursor: pointer;
    }
  }

  .no-results-wrapper {
    > p {
      margin: 0;
      border-bottom: 1px solid #3a3b3934;
      margin-bottom: 32px;
      padding-bottom: 32px;
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    margin: 40px auto 40px auto;
    width: 135px;

    .counter {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25em;
      line-height: 17px;
      margin: auto;
      text-align: center;
    }

    .icon {
      cursor: pointer;
      height: 32px;
      width: 32px;
    }
  }

  &.dark-mode {
    .search-result-text {
      color: $white;
    }

    .no-results-wrapper {
      > p {
        color: $white;
      }
    }

    .search-tag-filters {
      .tag-filter {
        color: #A3A3A2;

        &.active-tag {
          color: $yellow;
        }

        &:hover:not(.active-tag) {
          color: $yellow;
        }
      }
    }

    .counter {
      color: $white;
    }
  }

  &.light-mode {
    .search-result-text {
      color: $gray;
    }

    .no-results-wrapper {
      > p {
        color: $gray;
      }
    }

    .search-tag-filters {
      .tag-filter {
        color: #494538;

        &.active-tag {
          color: $yellow;
        }

        &:hover:not(.active-tag) {
          color: $yellow;
        }
      }
    }

    .counter {
      color: $gray;
    }
  }
}

@media screen and (max-width: $md) {
  .topics-listing-wrapper {
    padding: 20px 0 80px 0;

    .slick-slider {
      margin-top: 30px;
      width: 100%;
    }

    .listing-title,
    .topic-title {
      font-size: 30px;
      line-height: 28px;
      margin: 40px 0 10px 20px;
    }

    .filters {
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 20px;

      .left-filters {
        border-bottom: none;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
      }
    }

    .search-result-text {
      display: none;
    }

    .contents {
      display: grid !important;
      gap: 20px !important;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .listing,
      .tagged-image-wrapper {
        padding: 0 20px;
      }
    }

    &.dark-mode {
      .search-input-wrapper {
        border-bottom: 1px solid #ffffff36;
      }
    }

    &.light-mode {
      .search-input-wrapper {
        border-bottom: 1px solid #3a3b3934;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .topics-listing-wrapper {
    padding: 20px 16px 100px 16px;

    .slick-slider {
      margin-left: -100px;
      width: calc(100% + 200px);
    }

    .listing-title,
    .topic-title {
      font-size: 52px;
      line-height: 67px;
      margin: 30px 0 25px 0;
    }

    .filters {
      .left-filters {
        flex-direction: row;
      }

      .search-input-wrapper {
        border-bottom: none;
      }
    }

    .search-result-text {
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 30px;

      .bold {
        font-weight: 700;
      }

      .clear-result-text {
        cursor: pointer;
        font-weight: 700;
        margin-left: 10px;
        text-decoration: underline;
      }
    }

    .contents {
      display: grid !important;
      gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: 0 15px;
      max-width: $contentMaxWidthWide;
      padding: 33px 100px 40px 100px;

      .tagged-image-wrapper {
        margin: 5px;
      }
    }

    &.dark-mode {
      .left-filters {
        border-bottom: 1px solid #ffffff36;
      }
    }

    &.light-mode {
      .left-filters {
        border-bottom: 1px solid #3a3b3934;
      }
    }
  }
}
