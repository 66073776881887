@import '../../styles/variables';

.arrow-btn{
  svg{
    height: 100%;
    width:100%;

    circle.circle{
      fill: none;
      transition: fill 0.3s ease-in-out;
    }

    path.path1{
      fill: $yellow;
      transition: fill 0.3s ease-in-out;
    }

    path.path2{
      fill: #FEFAEE33;
      transition: fill 0.3s ease-in-out;
    }
  }

  &:hover{
    svg{
      path.path2{
        fill: $yellow;
        transition: fill 0.3s ease-in-out;
      }
    }
  }

  &.light-mode{
    svg{
      circle.circle{
        fill: none;
        transition: fill 0.3s ease-in-out;
      }

      path.path1{
        fill: $gray;
        transition: fill 0.3s ease-in-out;
      }

      path.path2{
        fill: $gray;
        transition: fill 0.3s ease-in-out;
      }
    }

    &:hover{
      circle.circle{
        fill: $gray;
        transition: fill 0.3s ease-in-out;
      }

      svg{
        path.path1{
            fill: $yellow;
            transition: fill 0.1s ease-in-out;
        }
      }
    }
  }
}