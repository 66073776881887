@import '../../styles/variables';

.home-hero-slider-wrapper {
  .home-hero-wrapper {
    position: relative;
    width: 100%;

    .background-gradient {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.8) 0.01%,
          rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(0deg, rgba(61, 56, 52, 0.7), rgba(61, 56, 52, 0.7));
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .background-image {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .bottom-content {
        margin-top: auto;
        padding: 0 16px;
        width: 100%;

        .tag-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 8px;

          .tag {
            background-color: #494538;
            color: $yellow;
          }
        }

        .title {
          font-weight: 500;
          letter-spacing: 0;
          margin: 10px 0 15px 0;
          max-width: 992px;
        }

        .flex-content {
          .action-button {
            margin: auto 0;
            max-width: fit-content;
          }
        }
      }
    }
  }

  .slide-counter {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;

    .counter {
      color: #fffffe;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25em;
      line-height: 17px;
      text-align: center;
      width: 80px;
    }

    .icon {
      height: 32px;
      width: 32px;
    }
  }
}

@media screen and (max-width: $lg) {
  .home-hero-slider-wrapper {
    .home-hero-wrapper {
      height: 510px;

      .content-wrapper {
        .bottom-content {
          box-sizing: border-box;
          max-width: 100%;

          .title {
            font-size: 32px;
            line-height: 44px;
          }

          .flex-content {
            flex-direction: column;
            margin: 30px 0 130px 0;
          }
        }
      }
    }

    .slide-counter {
      border-top: 1px solid #ffffff30;
      margin: -90px 20px 0 20px;
      position: absolute;
      width: -webkit-fill-available;

      .icon {
        height: 32px;
        margin: 10px 0;
        width: 32px;
      }
    }
  }

  .single-contentzone {
    .home-hero-slider-wrapper {
      .slide-counter {
        left: 16px;
        right: 16px;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .home-hero-slider-wrapper {
    .home-hero-wrapper {
      height: calc(100vh - 175px);

      .content-wrapper {
        .bottom-content {
          .title {
            font-size: 62px;
            line-height: 75px;
          }

          .flex-content {
            display: flex;
            flex-direction: row;
            margin: 70px 0 110px 0;
          }
        }
      }
    }

    .slide-counter {
      border-top: none;
      justify-content: flex-end;
      margin: auto;
      max-width: 1400px;
      padding: 0 16px;
      position: relative;
      top: -176px;

      .counter {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.25em;
        line-height: 17px;
        text-align: center;
        width: 80px;
      }

      .icon {
        height: 32px;
        margin: unset;
        width: 32px;
      }
    }
  }

  .single-contentzone {
    .home-hero-slider-wrapper {
      .slide-counter {
        left: 0px;
        right: 0px;
      }
    }
  }
}

.dark-mode {
  .title {
    color: $white;
  }

  .slide-counter {
    border-top: $gray 2px solid;

    .counter {
      color: $white;
    }
  }
}

.light-mode {
  .title {
    color: $gray;
  }

  .slide-counter {
    border-top: $gray 2px solid;

    .counter {
      color: $gray;
    }
  }
}
