@import '../../styles/_variables.scss';

.ancher-navigation {
  background-color: $gold;
  color: $white;
  margin-bottom: var(--spacing-bit_more_relaxed);

  &.text-center {
    .nav-list {
      justify-content: center;
    }
  }

  &.text-left {
    .nav-list {
      justify-content: flex-start;
    }
  }

  &.text-right {
    .nav-list {
      justify-content: flex-end;
    }
  }

  .title {
    color: $white;
    font-family: var(--font-family) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 24px;
    margin: 0;
    text-align: center;
    text-transform: uppercase;

    &.active {
      color: $yellow;
      font-weight: 600;
    }

    &:hover {
      color: $yellow;
    }
  }

  .mobile-active {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px;

    img,
    svg {
      margin-left: 8px;
      margin-top: 2px;
      width: 8px;
    }
  }

  .nav-list {
    background-color: rgba(73, 69, 56, 0.1);
    display: none;

    &.active {
      display: block;
    }

    li {
      border-top: 1px solid rgba(255, 255, 255, 0.25);

      a {
        color: $white;
        display: block;
        padding: 21px 24px;
        text-decoration: none;

        &:hover {
          color: $yellow;
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    margin-bottom: 0;

    .mobile-active {
      display: none;
    }

    .nav-list {
      display: flex;
      padding: 0 16px;

      &.active {
        display: flex;
      }

      li {
        border-top: none;
        display: inline-block;

        a {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
  }

  &.dark-mode {
    background-color: $gold;
    color: $white;

    .title {
      &.active {
        color: $yellow;
      }
    }
  }

  &.light-mode {
    background-color: $cream;
    color: $black;

    .title {
      color: $black;

      &.active,
      &:hover {
        color: $gold2;
      }
    }

    .nav-list {
      li {
        border-top-color: rgba(255, 255, 255, 0.75);
      }
    }
  }
}
