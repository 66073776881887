@import '../../styles/variables';

.text-image-cta-wrapper {
  background: white;
  margin: 0 auto;
  position: relative;
  width: 100%;

  .text-image-cta-gradient {
    background: #3d3834b2;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;

    .centered-content {
      margin: auto 0;

      .title {
        font-weight: 500;
      }

      .button {
        border-radius: 100px;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 17px;
        text-transform: uppercase;
        width: fit-content;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .text-image-cta-wrapper {
    height: 570px;

    .content {
      bottom: 50px;
      left: 0;

      .centered-content {
        margin: auto 0 0 0;

        .title {
          font-size: 32px;
          line-height: 40px;
          max-width: 500px;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .text-image-cta-wrapper {
    height: 550px;

    .content {
      left: 50%;
      max-width: 1400px;
      padding: 0 16px;
      top: 50%;
      transform: translate(-50%, -50%);

      .centered-content {
        margin: auto 0;
        max-width: 750px;

        .title {
          font-size: 52px;
          line-height: 62px;
        }
      }
    }
  }
}

.dark-mode {
  .centered-content {
    .title {
      color: $white;
    }

    .button {
      background-color: $white;
      color: $black2;

      &:hover {
        background-color: $black2;
        color: $white;
      }
    }
  }
}

.light-mode {
  .centered-content {
    .title {
      color: $gray;
    }

    .button {
      background-color: $black2;
      color: $white;

      &:hover {
        background-color: $white;
        color: $black2;
      }
    }
  }
}
