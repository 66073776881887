@import '../../styles/_variables.scss';

.cta-button {
  // The `cta-button` is left over from wordpress. In order to not
  // have to manually update 500 articles, we made the decision to support
  // this class for legacy articles. It should not be used in future development
  min-height: 30px;
  width: fit-content;
}

.anchor-link,
.btn,
.cta-button {
  align-items: center;
  border: none;
  border-radius: 200px;
  box-sizing: border-box;
  column-gap: 8px;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  justify-content: center;
  line-height: 120%;
  letter-spacing: 2.5px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;

  &.dark-mode {
    &.size-large,
    &.size-medium,
    &.size-small {
      &.btn-primary {
        background-color: var(--white);
        border: none;
        color: var(--black);
      }

      &.btn-secondary {
        background-color: transparent;
        border: 1px solid var(--white);
        color: var(--white);
      }

      &.link-tertiary,
      &.btn-tertiary {
        color: var(--white);
      }
    }

    &.btn-primary {
      &:hover {
        background-color: $black;
        color: $white;
      }

      &.pressed,
      &:active {
        background-color: $black;
        color: $white;
      }
    }

    &.btn-secondary {
      &:hover {
        border-color: var(--white-overlay-60);
        color: var(--white-overlay-60);
      }

      &.pressed,
      &:active {
        background-color: var(--white-overlay-05);
        border-color: var(--white-overlay-60);
        color: var(--white-overlay-60);
      }
    }

    &.link-tertiary,
    &.btn-tertiary {
      &:hover,
      &.pressed,
      &:active {
        color: var(--white-overlay-60);
      }
    }
  }

  &.share-link {
    padding: 0 !important;
    text-decoration: none !important;

    .icon {
      font-size: var(--f-huge);
    }
  }

  &.image-link {
    .image-link-image {
      height: auto;
      max-width: 100%;
      width: auto;
    }
  }

  &.size-small {
    font-size: 14px;
    font-weight: 700;
    min-height: 30px;
    padding: 6px 28px;
  }

  &.size-medium {
    font-size: 14px;
    font-weight: 700;
    min-height: 30px;
    padding: 6px 28px;
  }

  &.size-large {
    font-size: 14px;
    font-weight: 700;
    min-height: 40px;
    padding: 17px 32px;
  }

  &.link-tertiary,
  &.btn-tertiary,
  &.btn-secondary,
  &.btn-primary {
    &.icon-to-left,
    &.icon-to-right {
      position: relative;

      .icon {
        position: absolute;
      }

      .button-text {
        @media screen and (max-width: $md) {
          padding: 0 2rem !important;
        }
      }

      &.icon-to-left {
        .button-text {
          padding: 0 0 0 2rem;
        }
      }

      &.icon-to-right {
        .button-text {
          padding: 0 2rem 0 0;
        }
      }
    }

    &.icon-to-left {
      .icon {
        left: 2rem;
      }
    }

    &.icon-to-right {
      .icon {
        right: 2rem;
      }
    }
  }
}

.anchor-link {
  min-height: auto !important;

  .link-text {
    font-size: 20px;
  }

  &.size-small {
    column-gap: 4px;
    font-weight: 400;
  }

  &.link-tertiary,
  &.btn-tertiary {
    &.icon-to-left {
      .icon {
        left: 0;
      }

      .link-text {
        padding-left: 1.5rem;
      }
    }

    &.icon-to-right {
      .icon {
        right: 0;
      }

      .link-text {
        padding-right: 1.5rem;
      }
    }

    @media screen and (max-width: $md) {
      &.icon-to-left {
        .icon {
          padding-right: 8px;
        }
      }

      &.icon-to-right {
        display: flex;
        flex-direction: row-reverse;

        .icon {
          padding-left: 0;
          right: 0;
        }
      }

      .icon {
        position: relative !important;
      }

      .link-text {
        padding: 0 !important;
      }
    }
  }
}

.btn-primary,
.cta-button {
  background-color: $yellow;
  border-radius: 100px;
  color: #191a18;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.2em;
  padding: 1px 27px;
  text-transform: uppercase;

  &:hover {
    background-color: #191a18;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    color: $yellow;
  }

  &:disabled {
    background-color: var(--gray-10);
    border-color: transparent;
    color: #9f9fa0;

    &:hover {
      box-shadow: none;
    }
  }

  &:focus {
    position: relative;

    &::after {
      border: 1px solid var(--black);
      border-radius: inherit;
      height: calc(100% + 4px);
      position: absolute;
      width: calc(100% + 4px);
    }
  }

  &.pressed,
  &:active {
    &:not(:disabled) {
      background-color: var(--grayscale);
      box-shadow: unset;
      color: var(--light-grayscale);
    }

    &::after {
      border: none !important;
    }
  }
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid;
  border-color: var(--black);
  color: var(--black);

  &.border-color-giving_green {
    border-color: rgba(9, 193, 161, 0.4);
  }

  &.border-color-blue {
    border-color: rgba(0, 168, 216, 0.4);
  }

  &:hover {
    border-color: var(--black-overlay-60);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
    color: var(--black-overlay-50);
  }

  &.pressed,
  &:active {
    background-color: var(--black-overlay-05);
    border-color: var(--black-overlay-60);
    box-shadow: none;
    color: var(--black-overlay-50);
    position: relative;
  }

  &:disabled {
    color: #9f9fa0;
    background-color: var(--gray-10);
    border-color: transparent;

    &:hover {
      box-shadow: none;
    }
  }
}

.link-tertiary {
  padding: 0 !important;
}

.link-tertiary,
.btn-tertiary {
  background: none;
  color: var(--black);

  .link-text {
    text-decoration: none;
  }

  &:hover {
    color: var(--black-overlay-60);
    text-decoration: none;

    .link-text {
      text-decoration: underline;
    }
  }

  &:active {
    color: var(--black-overlay-60);
    text-decoration: none !important;

    .link-text {
      text-decoration: none;
    }
  }

  .icon,
  .link-text {
    margin-left: unset !important;
    margin-right: unset !important;
  }

  &.size-small {
    .link-text {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &.size-medium {
    .link-text {
      font-size: 16px;
    }
  }
}

.btn-channel {
  background-color: var(--white);
  border-radius: 8px !important;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.15);
  height: 64px;
  padding: 0 !important;
  width: 256px;

  &.dark-mode {
    background-color: transparent;
  }

  .button-text {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
    transition: 0.25s ease-in;
    transform: translateY(-2px);
  }

  > span {
    border-radius: 8px;
  }
}
