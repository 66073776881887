@import '../../styles/variables';

.journey-hero-wrapper {
  position: relative;
  width: 100%;

  .background-gradient {
    background: #3d383466;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .background-image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .breadcrumb {
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    top: 0;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .centered-content {
      .tag-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;

        .tag {
          background-color: #494538;
          color: $yellow;
        }
      }

      .title {
        color: $white;
        font-weight: 500;
        letter-spacing: 0;
        margin: 10px 0 15px 0;
        max-width: 700px;
      }

      .parts-tag {
        background-color: #100f0e;
        border-radius: 50px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 2px 15px;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $md) {
  .journey-hero-wrapper {
    height: 735px;

    .background-gradient {
      background-image: linear-gradient(0deg,
          #191a18 -7.78%,
          rgba(25, 26, 24, 0) 96.97%);
    }

    .breadcrumb {
      color: $black2;
    }

    .centered-content {
      margin: auto 0;

      .tag-wrapper {
        margin-top: -160px;
      }

      .title {
        font-size: 32px;
        line-height: 44px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .journey-hero-wrapper {
    height: 1000px;

    .background-gradient {
      background-image: linear-gradient(180deg, transparent, $black2);
    }

    .breadcrumb {
      color: $white;
    }

    .content-wrapper {
      box-sizing: border-box;
    }

    .centered-content {
      margin: auto 0;

      .tag-wrapper {
        margin-top: -250px;
      }

      .title {
        font-size: 62px;
        line-height: 75px;
      }
    }
  }
}

@media screen and (min-width: $xl) {
  .journey-hero-wrapper {
    .content-wrapper {
      &.container {
        padding: 0px;
      }
    }
  }
}