@import '../../styles/_variables.scss';

.rich-text {
  font-family: var(--font-family);
  font-size: var(--f-normal);
  font-weight: 500;

  &.heading {
    font-family: var(--font-family-editor);
  }

  blockquote {
    border-left: 2px solid $yellow;
    font-family: var(--font-family-editor);
    font-size: 28px;
    font-style: italic;
    margin: 0 0 var(--spacing-normal);
    padding: 0 var(--spacing-normal);

    * {
      font-weight: 500;
    }

    p:last-of-type {
      margin: 0px;
    }
  }

  > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    font-weight: normal;
    line-height: 160%;
    margin: 0 0 1rem;
  }

  &.no-padding {
    padding: 0;
  }

  ul {
    list-style: initial;
    padding-left: 40px;
  }

  &.text-white {
    p {
      color: $white;
    }
  }
  &.text-black {
    p {
      color: $black;
    }
  }
  &.text-yellow {
    h1,
    h2,
    h3,
    h4,
    p {
      color: $yellow;
    }
  }
  ul,
  ol {
    margin: 0 0 1rem;
  }
}

@media screen and (min-width: $md) {
  .rich-text {
    blockquote {
      font-size: 52px;
    }
  }
}
