@import '../../styles/_variables.scss';

.youtube-video-wrapper {
  height: unset;
  text-align: center;
  width: 100%;

  > div {
    padding-top: 56.25%;
    position: relative;
  }

  &.portrait {
    > div {
      padding-top: 177.78%;
    }
  }

  .youtube-iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .youtube-placeholder {
    background-color: rgba(0, 0, 0, 0.3875);
    background-position: center center;
    background-size: cover;
    contain: content;
    display: block;
    max-width: 1000px;
    position: relative;
    width: 100%;

    // Top gradient fade.
    &::before {
      background-position: top;
      background-repeat: repeat-x;
      content: '';
      display: block;
      height: 60px;
      padding-bottom: 50px;
      position: absolute;
      top: 0;
      transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
      width: 100%;
    }

    .media-play-loader {
      animation: rotate 1s linear infinite;
      border-radius: 50%;
      height: 48px;
      left: calc(50% - 24px);
      margin: 0;
      position: absolute;
      top: calc(50% - 24px);
      width: 48px;

      &::before {
        animation: clipFx 3s linear infinite;
        border: 4px solid #fff;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        inset: 0px;
        position: absolute;
      }

      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes clipFx {
        0% {
          clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }
        25% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }
        50% {
          clip-path: polygon(
            50% 50%,
            0 0,
            100% 0,
            100% 100%,
            100% 100%,
            100% 100%
          );
        }
        75% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }
        100% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
      }
    }

    // Optional class: Play button, with YouTube's actual play button svg.
    > .youtube-play-btn {
      background-color: transparent;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
      border: none;
      cursor: pointer;
      display: block;
      filter: grayscale(100%);
      height: 48px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
      width: 68px;
      z-index: 1;
    }
  }

  &.hide-info {
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    position: relative;

    .youtube-iframe {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 150%;

      @media screen and (max-width: $md) {
        height: 500%;
        top: -200%;
        width: 400%;
      }

      @media screen and (max-width: $lg) and (min-width: $md) {
        height: 400%;
        top: -150%;
        width: 250%;
      }

      @media screen and (min-width: $md) {
        height: 300%;
        top: -100%;
      }
    }
  }
  button.ytp-large-play-button {
    svg {
      display: none !important;
    }
  }
}
