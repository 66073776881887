@import '../../styles/variables';

.search-tag-filters {
  color: #A3A3A2;
  display: flex;
  flex-wrap: wrap;

  .tag-filter {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 6px;
    justify-content: center;
    padding: 8px 10px;
    text-transform: capitalize;

    span {
      font-size: 14px;
      font-weight: 600px;
    }

    svg {
      opacity: 0;
      transform: translateY(1px);
      visibility: hidden;
    }

    &.active-tag {
      background-color: #494538;
      border-radius: 20px;
      color: $yellow;

      svg {
        opacity: 1;
        visibility: visible;
      }
    }

    &:hover:not(.active-tag) {
      color: $yellow;
    }
  }
}

.bg-gold {
  .dark-mode {
    .search-tag-filters {
      .tag-filter {
        &.active-tag {
          background-color: $black2;
        }
      }
    }
  }
}