@import '../../styles/variables';

.search-box{
    margin-right: 16px;
    width: 200px;
    >form{
        width: 190px;
    }
    &.full-search{
        width: 100%;
        >form{
            width: 100%;
        }
        .input-container{
            border-bottom: 1px solid #3a3b3934;
            padding-bottom: 15px;
            .search-button{
                cursor: auto;
            }
            .input {
                color: $black2;
                font-weight: 600;
                line-height: 22px;
                margin: auto 0;
                background: none;
                border: none;
                outline: none;
                width:100%;
              }
        }

    }
    &.closed{
        width: 30px;
        
    }
    input{
        width: 190px; 
        &.hidden{
            width: 0px !important;
        }
    }
    .input-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        .icon {
          width: 18px;
          height: 18px;
          margin: 4px 20px auto 0;
        }
        .input {
          font-family: var(--font-family);
          color: $black2;
          font-weight: 400;
          line-height: 17px;
          font-size: 14px;
          margin-top: -5px;
          background: none;
          border: none;
          outline: none;
          width:100%;
        }
        .input::placeholder {
          color: #a4a4a4;
          opacity: 60%;
        }
    }
    &.dark-mode{
        .input-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            .icon {
              width: 18px;
              height: 18px;
              margin: 4px 20px auto 0;
            }
            .input {
                color: #fffffe;
                opacity: 60%;
            }
        }
    }
}

