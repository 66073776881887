@import '../../styles/variables';

.hub-hero-wrapper {
  position: relative;
  width: 100%;

  .background-gradient {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .image {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .breadcrumb {
    font-size: 14px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 16px;
    position: absolute;
    top: 0;

    .yellow {
      font-weight: 700;
    }
  }

  .content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 50%;
    max-width: 1400px;
    padding: 20px 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .title {
      font-weight: 500;
      line-height: 92px;
      letter-spacing: 0;
      margin: auto 0;
    }
  }
}

@media screen and (max-width: $md) {
  .hub-hero-wrapper {
    height: 300px;

    .content-wrapper {
      .title {
        font-size: 32px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .hub-hero-wrapper {
    height: 480px;
    .content-wrapper {
      .title {
        font-size: 52px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .text-image-cta-wrapper {
    height: 550px;

    .content {
      left: 0;
      max-width: 750px;
      padding: 15px 75px;
      top: 0;

      .centered-content {
        margin: auto 0;

        .title {
          font-size: 52px;
          line-height: 62px;
        }
      }
    }
  }
}

@media screen and (min-width: $xl) {
  .hub-hero-wrapper {
    .content-wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

}

.dark-mode {
  .title {
    color: $white;
  }
}

.light-mode {
  .title {
    color: $gray;
  }
}

.hub-hero-wrapper.gradient-dark {
  .background-gradient {
    background: linear-gradient(180deg, rgba(58, 59, 57, 0) 0%, $black2);
  }
}
.hub-hero-wrapper.gradient-cream {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $cream);
  }
}
.hub-hero-wrapper.gradient-gold {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gold);
  }
}
.hub-hero-wrapper.gradient-gray {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gray);
  }
}
