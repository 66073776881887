@import '../../styles/_variables.scss';

.dual-contentzone {
  align-items: center;
  overflow: hidden;
  position: relative;
  vertical-align: middle;

  &.height-70vh {
    height: 70vh;

    .container {
      @media only screen and (min-width: $md) {
        top: 50%;
        transform: translateY(-50%);
      }

      @media screen and (max-width: $md) {
        height: 100%;

        .bg {
          flex: 1;

          &.left-area {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  .bg,
  &.bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.bg-area-left,
    &.bg-area-right {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
      z-index: 0;

      @media only screen and (max-width: $md) {
        height: auto;
        width: 100%;
      }
    }

    &.bg-area-left {
      @media only screen and (max-width: $md) {
        &.reversed {
          bottom: 0;
          top: unset;
        }
      }
    }

    &.bg-area-right {
      left: unset;
      right: 0;

      @media only screen and (max-width: $md) {
        bottom: 0;
        top: unset;

        &.reversed {
          bottom: unset;
          top: 0;
        }
      }
    }
  }

  &.bg-vertical {
    .bg {
      &.bg-area-left,
      &.bg-area-right {
        height: auto;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 0;
      }

      &.bg-area-left {
        top: 0;

        &.reversed {
          bottom: 0;
          top: unset;
        }
      }

      &.bg-area-right {
        bottom: 0;
        top: unset;

        &.reversed {
          bottom: unset;
          top: 0;
        }
      }
    }
  }

  &.rounded-corners {
    border-radius: var(--radius-s);
  }

  .video-background {
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &.mt-56 {
    margin-top: 56px;
  }

  .singleIcon {
    border-radius: 100%;
    height: 86px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 86px;

    &.p-10 {
      padding: 10px;
    }

    img {
      border-radius: 100%;
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }

  > .container {
    display: flex;
    position: relative;

    & > div .container {
      padding: 0;
    }

    &.direction-horizontal {
      flex-direction: row;
      justify-content: space-between;

      &.vertical-center {
        align-items: center;
      }

      &.s-between-very_relaxed {
        .left-area,
        .right-area {
          width: calc(50% - 32px);
        }
      }

      &.s-between-more_relaxed {
        .left-area,
        .right-area {
          width: calc(50% - 16px);
        }
      }

      &.s-between-normal {
        .left-area,
        .right-area {
          width: calc(50% - 8px);
        }
      }

      &.s-between-none {
        .left-area,
        .right-area {
          width: 50%;
        }
      }
    }

    &.direction-vertical {
      flex-direction: column;

      &:not(.reversed) {
        &.s-between-very_relaxed {
          .left-area {
            margin-bottom: 64px;
          }
        }

        &.s-between-more_relaxed {
          .left-area {
            margin-bottom: 32px;
          }
        }

        &.s-between-normal {
          .left-area {
            margin-bottom: 16px;
          }
        }

        &.s-between-none {
          .left-area {
            margin-bottom: 0;
          }
        }
      }

      &.reversed {
        flex-direction: column-reverse;

        &.s-between-very_relaxed {
          .right-area {
            margin-bottom: 64px;
          }
        }

        &.s-between-more_relaxed {
          .right-area {
            margin-bottom: 32px;
          }
        }

        &.s-between-normal {
          .right-area {
            margin-bottom: 16px;
          }
        }

        &.s-between-none {
          .right-area {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .left-align {
    a:first-child {
      margin-left: 0;
    }
  }

  .right-align {
    a:last-child {
      margin-right: 0;
    }
  }
}
