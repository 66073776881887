@import '../../styles/variables';

.audio-wrapper {
  background-color: $black2;
  border-radius: 8px;
  display: flex;
  margin: 24px auto;
  max-width: 787px;
  padding: 16px;

  @media screen and (max-width: $md) {
    align-items: center;
    flex-direction: column;
    margin: 24px;

    .player-wrapper {
      width: 100%;

      .text-wrapper {
        margin-bottom: 12px;
      }

      .rhap_current-time {
        display: none;
      }
    }
  }

  .audio-image {
    border-radius: 8px;
    height: 200px;
    margin-right: 16px;
    width: 200px;
  }

  .player-wrapper {
    width: 100%;

    .text-wrapper {
      .title {
        color: white;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 12px;
      }

      .subtext {
        color: #fbf9f6;
        font-size: 14px;
        line-height: 17px;
        opacity: 60%;
      }
    }
  }

  @media screen and (min-width: $md) {
    .player-wrapper {
      .text-wrapper {
        margin-bottom: 36px;
      }
    }
  }
}
