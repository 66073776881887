.embed-item,
.form-wrapper {
  .form {
    margin: auto;

    h1,
    h2,
    h3,
    h4 {
      color: $white;
      font-size: 32px;
      text-align: left;
    }

    p {
      color: #fbf9f6;
      text-align: left;
      opacity: 50%;
    }

    .input {
      > input {
        background-color: transparent;
        border: none;
        border-bottom: solid $gray 1px;
        border-radius: 0%;
        color: $white;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .actions {
      > input {
        background-color: $black2;
        border-color: $gray;
        border-width: 2px;
        color: $yellow;
        margin: 8px;
      }
    }
  }

  .class-applied-to-form {
    max-width: 576px !important;

    &.container {
      padding: var(--spacing-bit_more_relaxed);
    }

    .input {
      > input,
      > textarea {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
        color: $yellow;
        font-size: var(--f-normal);

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      > select {
        outline: none;
      }

      > textarea {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      > .hs-dateinput {
        > input {
          &:focus {
            outline: none;
          }
        }
      }

      .hs-input[type='file'] {
        appearance: none;
        position: relative;
      }

      .hs-input[type='file']::-webkit-file-upload-button {
        visibility: hidden;
      }

      .hs-input[type='file']::before {
        background-color: $yellow;
        border-radius: 100px;
        color: var(--black);
        content: 'Choose File';
        cursor: pointer;
        font-size: 14px;
        left: 0;
        padding: 2px 8px;
        position: absolute;
      }
    }

    label {
      color: var(--white);
      display: block;
      font-size: var(--f-feature_paragraph);
      font-weight: 600;
      line-height: var(--line-height-tighter);
      margin-bottom: var(--spacing-even_tighter);
      text-align: left;

      .hs-form-required {
        color: var(--white);
      }
    }

    p {
      margin-bottom: 24px;
      margin-top: 0;
    }

    .hs-error-msgs {
      margin-top: var(--spacing-tighter);

      label {
        color: var(--red_again);
        font-size: var(--f-xsmall);
        font-style: normal;
        font-weight: normal;
        line-height: var(--line-height-tighter);
        margin-bottom: var(--spacing-slight_relax);
      }
    }

    .hs-form-field {
      margin-bottom: var(--spacing-relaxed);
    }

    fieldset {
      max-width: 100% !important;
    }

    .hs-error-msg {
      color: var(--error_red);
    }

    textarea {
      padding: var(--spacing-even_tighter) 0 !important;
    }

    input.hs-input {
      box-sizing: border-box !important;
      font-size: var(--f-normal) !important;
      padding: var(--spacing-even_tighter) 0 !important;
      width: 100% !important;

      &.error {
        border-color: var(--error_red) !important;
        color: var(--error_red) !important;
      }
    }

    .hs-form-radio label {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;

      input.hs-input {
        display: none;
        width: auto;

        &:checked {
          color: var(--yellow);

          + span {
            color: var(--yellow);
            font-weight: 400;
            position: relative;

            &::before {
              background-color: var(--black);
              border: 2px solid var(--yellow);
              height: 15px;
              width: 15px;
            }

            &::after {
              background-color: var(--yellow);
              border-radius: 50%;
              content: '';
              display: block;
              height: 7px;
              left: 4px;
              position: absolute;
              top: calc(50% - 3.5px);
              width: 7px;
            }
          }
        }
      }

      input.hs-input:not(:disabled):hover {
        + span {
          color: var(--yellow);

          &::before {
            background-color: transparent;
            border-color: var(--yellow);
            border-width: 2px;
          }
        }
      }

      input.hs-input:disabled {
        + span {
          color: var(--gray-translusant);

          &::before {
            border-color: var(--gray-translusant);
          }
        }

        &:checked {
          + span {
            &::after {
              background-color: var(--gray-translusant);
            }
          }
        }
      }

      span {
        align-items: center;
        color: var(--white);
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 31px;

        &::before {
          border: 1px solid var(--white);
          border-radius: 100%;
          box-sizing: border-box;
          content: '';
          display: inline-block;
          height: 15px;
          margin-right: var(--spacing-tighter);
          width: 15px;
        }
      }
    }

    .hs-richtext.hs-main-font-element {
      p {
        color: var(--white);
        font-size: var(--f-normal);
        font-weight: 400;
        line-height: var(--line-height-normal);
        text-align: left;
      }
    }

    .hs-form-checkbox label {
      color: var(--white);
      font-size: var(--f-normal);
      font-weight: 400;
      line-height: var(--line-height-normal);

      input.hs-input {
        display: none;
        width: auto;

        &:checked {
          color: var(--yellow);

          + span {
            color: var(--yellow);
            font-weight: 400;

            &::before {
              background-color: var(--yellow);
              border-color: var(--yellow);
            }

            &::after {
              background-image: url(./../../src/components/Form/Checkbox/checkmark-black.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              content: '';
              height: 9px;
              left: 3px;
              position: absolute;
              top: 11px;
              width: 9px;
            }
          }
        }
      }

      span {
        align-items: center;
        color: var(--white);
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 31px;
        padding-left: 22px;
        position: relative;

        &::before {
          border: 1px solid var(--white);
          border-radius: 2px;
          box-sizing: border-box;
          content: '';
          height: 15px;
          left: 0;
          width: 15px;
          position: absolute;
          top: 8px;
        }
      }

      input.hs-input:not(:disabled):not(:checked):hover {
        + span {
          color: var(--yellow);

          &::before {
            background-color: transparent;
            border-color: var(--yellow);
            border-width: 2px;
          }
        }
      }

      input.hs-input:disabled {
        + span {
          color: var(--gray-translusant);

          &::before {
            border-color: var(--gray-translusant);
          }
        }

        &:checked {
          + span {
            &:before {
              background-color: var(--gray-translusant);
            }
          }
        }
      }
    }

    .hs-dateinput {
      position: relative;

      &::after {
        background-image: url('../assets/date-picker.svg');
        content: '';
        height: 24px;
        position: absolute;
        right: 0;
        top: calc(50% - 12px);
        width: 24px;

        /* Better Font Rendering */
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }

      > input {
        appearance: none;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 0;
        box-sizing: border-box;
        color: var(--yellow);
        font-size: var(--f-normal);
        padding: var(--spacing-even_tighter) 0 !important;
        width: 100%;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */

        &.error {
          border-color: var(--error_red) !important;
          color: var(--error_red) !important;
        }

        &:hover {
          color: var(--yellow);
        }
      }
    }

    .hs-fieldtype-select {
      .input {
        position: relative;

        &::after {
          content: '\e96e';

          /* Use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icomoon', sans-serif !important;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          height: 18px;
          line-height: 1;
          position: absolute;
          right: 0;
          top: 9px;
          text-transform: none;
          width: 18px;

          /* Better Font Rendering */
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
        }

        > select {
          appearance: none;
          background-color: transparent;
          border: none;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 0;
          box-sizing: border-box;
          font-size: var(--f-normal);
          padding: var(--spacing-even_tighter) 0 !important;
          width: 100%;
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */

          &.error {
            border-color: var(--error_red) !important;
            color: var(--error_red) !important;
          }

          &:hover {
            color: var(--yellow);
          }
        }
      }
    }

    .hs-form-booleancheckbox-display {
      color: var(--white);
      font-size: var(--f-normal);
      font-weight: 400;
      line-height: var(--line-height-normal);

      input.hs-input {
        display: none;
        width: auto;

        &:checked {
          color: var(--yellow);

          + span {
            color: var(--yellow);
            font-weight: 400;

            &::before {
              background-color: var(--yellow);
              background-image: url(./../../src/components/Form/Checkbox/checkmark-black.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              border-color: var(--yellow);
            }
          }
        }
      }

      > span {
        color: var(--white);
        font-size: var(--f-normal);
        font-weight: normal;
        line-height: var(--line-height-tight);

        &::before {
          border: 1px solid #000000;
          border-radius: 2px;
          box-sizing: border-box;
          content: '';
          display: inline-block;
          height: 15px;
          margin-right: var(--spacing-tighter);
          min-width: 15px;
        }
      }

      .hs-form-required {
        font-size: var(--f-normal);
      }
    }
  }
}

.class-applied-to-form {
  margin: auto;

  .fieldset-columns-1,
  .fieldset-columns-2 {
    margin: auto;
  }

  .hs-error-msgs label {
    color: var(--error_red);
    font-size: var(--f-xsmall);
    font-style: normal;
    font-weight: normal;
    line-height: var(--line-height-tighter);
    margin-bottom: var(--spacing-slight_relax);
  }

  .btn.btn-primary.btn-medium {
    font-size: medium;
    height: 47px;
    margin: auto;
    min-width: 50%;
    padding-left: 24px;
    padding-right: 24px;
  }

  .hs-form-required {
    color: var(--yellow);
  }
}

.btn-inside-input {
  position: relative;

  form {
    position: relative;
  }

  .hs-email.hs-fieldtype-text.field.hs-form-field {
    margin-bottom: 40px;
  }

  .hs-email label {
    display: none;

    &.hs-error-msg {
      display: block;
    }
  }

  .hs-error-msgs,
  .hs_error_rollup {
    left: 24px;
    position: absolute;

    label {
      color: var(--error_red);
    }
  }

  .hs-error-msgs {
    left: calc(50% - 118px);
  }

  .hs_error_rollup {
    display: none;

    ul {
      left: calc(50% - 163px);
    }
  }

  .hs-input:focus {
    box-shadow: 0px 0px 0px 2px;
    outline: none;
  }

  .hs-input {
    background: transparent;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25px;
    padding: 14px 25px;
    width: 100%;
  }

  .hs-submit {
    bottom: 4px;
    position: absolute;
    right: 5px;

    .btn {
      font-size: medium;
      height: 47px;
      min-width: 90px;
    }
  }
}
