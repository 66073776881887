@import '../../styles/variables';

.small-journey-card-wrapper {
  cursor: inherit;
  display: inline-block;
  margin-right: 15px;
  position: relative;

  &.disable-click{
    pointer-events: none;
  }
  .small-journey-card-container {
    position: relative;
    height: 320px;
    .image-container {
      width: 100%;
      height: 100%;
      position: relative;
      .gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        background: #3d383466;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 100%
        );
      }
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 7px;
      }
    }
  }
  .small-journey-card-content {
    position: absolute;
    margin-top: auto;
    .tag-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      row-gap: 8px;
      z-index: 2;
      .tag {
        background-color: #494538;
        color: $yellow;
      }
    }
    .title {
      color: $white;
      font-weight: 500;
      margin: 20px 0 15px 0;
      white-space: pre-wrap;
    }
    .parts-tag {
      background-color: #100f0e;
      color: $white;
      border-radius: 50px;
      padding: 2px 15px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
    }
    .start-button {
      display: flex;
      margin-top: 15px;
      position: relative;
      width: 100%;
      z-index: 2;
    }
  }

  .card-main-link {
    inset: 0;
    position: absolute;
    z-index: 1;
  }
}

@media screen and (max-width: $md) {
  .small-journey-card-container {
    width: 240px;
    .small-journey-card-content {
      max-width: 200px;
      bottom: 20px;
      left: 20px;
      .title {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .small-journey-card-container {
    width: 320px;

    .small-journey-card-content {
      max-width: 265px;
      bottom: 30px;
      left: 25px;
      .title {
        font-size: 30px;
        line-height: 35px;
      }
      .start-button {
        display: none;
      }
    }
  }

  .card-main-link:hover {
    +.small-journey-card-container {
      .start-button {
        display: flex;
      }
    }
  }

  .small-journey-card-container:hover {
    .start-button {
      display: flex;
    }
  }
}
