:root {
  --lc_red: #a6192e;
  --shark: #1e1f23;
  --black: #000000;
  --dark-gray: #191a18;
  --blue-bg-light: #f2fafc;
  --gray: #404041;
  --grayscale: #2d2d2e;
  --light-grayscale: #c6c6c6;
  --medium_gray: #777779;
  --light_gray: #dad8d6;
  --lighter-gray: #f7f7f7;
  --lightest_gray: #f8f8f8;
  --lightest_gray-transparent: rgba(248, 248, 248, 0);
  --gray-translusant: #a8a7a180;
  --gray-130: #2d2d2e;
  --gray-100: #404041;
  --gray-80: #666667;
  --gray-50: #9f9fa0;
  --gray-30: #c6c6c6;
  --gray-10: #ececec;
  --gray-5: #f6f6f6;
  --white: #ffffff;
  --blue: #00a8d8;
  --cream: #fefaee;
  --yellow: #e6df69;
  --gold: #494538;
  --gold2: #a48d45;
  --prayerful_purple: #701789;
  --social_blues: #0ea9d6;
  --card-link: #009ecc;
  --card-link-20: rgba(0, 158, 204, 0.2);
  --card-link-lighter: #3cc1e9;
  --card-link-primary-lighter: #00b3e5;
  --card-link-primary-active: #008bb2;
  --sunshine: #ffba0c;
  --red_again: #e33300;
  --error_red: #ee4c4c;
  --made_new_blue: #004e7c;
  --giving_green: #09c1a1;
  --serving_blue: #1d70c9;
  --next_steps: #ffd60c;
  --black-overlay-90: rgba(0, 0, 0, 0.9);
  --black-overlay-60: rgba(0, 0, 0, 0.6);
  --black-overlay-50: rgba(0, 0, 0, 0.5);
  --black-overlay-05: rgba(0, 0, 0, 0.05);
  --white-overlay-60: rgba(255, 255, 255, 0.6);
  --white-overlay-05: rgba(255, 255, 255, 0.05);
  --new-here: #36d3e8;
  --design-note-pink: #ff0077;
  --life-groups: #00946e;
  --life-kids-1: #402061;
  --life-kids-2: #ffda24;
  --life-missions: #00788c;
  --local-missions: #ff4612;
  --open-network: #2993e5;
  --sisters: #b48a2f;
  --base: 16px;
  --f-huge: 3rem; // 48px
  --f-huge_desktop: 4.5rem; //72px
  --f-title: 2.5rem; // 40px
  --f-title_desktop: 4rem; // 64px
  --f-subtitle: 1.875rem; // 30px
  --f-subtitle_desktop: 3.25rem; // 52px
  --f-subhead: 1.5rem; // 24px
  --f-subhead_desktop: 2rem; // 32px
  --f-section_header: 2rem; // 32px
  --f-section_header_desktop: 2.5rem; // 40px
  --f-group_header: 1.5rem; // 24px
  --f-group_header_desktop: 2rem; // 32px
  --f-feature_paragraph: 1.25rem; // 20px
  --f-feature_paragraph_desktop: 1.5rem; // 24px
  --f-paragraph_large: 1.25rem; // 20px
  --f-normal: 1rem; // 16px
  --f-small: 0.875rem; // 14px
  --f-xsmall: 0.75rem; // 12px
  --spacing-none: 0rem; // 0px
  --spacing-tightest: 0.125rem; // 2px
  --spacing-even_tighter: 0.25rem; // 4px
  --spacing-tighter: 0.5rem; // 8px
  --spacing-tight: 0.5625rem; // 9px
  --spacing-slight_tight: 0.625rem; // 10px
  --spacing-more_slight_tight: 0.75rem; // 12px
  --spacing-normal: 1rem; // 16px
  --spacing-slight_relax: 1.125rem; // 18px
  --spacing-kinda_relax: 1.25rem; // 20px
  --spacing-relaxed: 1.5rem; // 24px
  --spacing-bit_more_relaxed: 2rem; // 32px
  --spacing-more_relaxed: 2.25rem; // 36px
  --spacing-slightly_more_relaxed: 3rem; // 48px
  --spacing-slightly_abit_more_relaxed: 3.25rem; // 52px
  --spacing-very_relaxed: 4rem; // 64px
  --spacing-even_more_relaxed: 4.5rem; // 72px
  --spacing-even_extra_more_relaxed: 5em; // 80px
  --spacing-a_little_relaxed: 5.9375em; // 95px
  --spacing-so_relaxed: 7rem; // 112px
  --spacing-extreme_relax: 8rem; // 128px
  --spacing-most_relaxed: 10rem; // 160px
  --fw-black: 600;
  --fw-bold: 600;
  --fw-semi_bold: 500;
  --fw-regular: 400;
  --radius-xs: 0.25rem; // 4px
  --radius-s: 0.5rem; // 8px
  --radius-m: 1.75rem; // 28px
  --radius-l: 12.5rem; // 200px
  --line-height-tightest: 100%; // 16px
  --line-height-tighter: 120%; // 19.2px
  --line-height-tight: 130%; // 20.8px
  --line-height-normal: 160%; // 25.6px
  --line-height-slight_relax: 171%; // 42.68px
  --line-height-relaxed: 200%; // 49.92px
  --shadow-sm: 0px 1px 3px rgba(0, 0, 0, 0.25);
  --shadow-m: 0px 2px 6px rgba(0, 0, 0, 0.25);
  --shadow-l: 0px 4px 12px rgba(0, 0, 0, 0.25);
  --shadow-xl: 0px 12px 36px rgba(0, 0, 0, 0.25);
  --font-family: proxima_nova, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-family-editor: editors_note, Helvetica Neue, Helvetica, Arial, sans-serif;
}

$typography: (
  huge: (
    font-size: var(--f-huge),
    font-weight: var(--fw-black),
    line-height: var(--line-height-tightest),
    margin-bottom: var(--spacing-tight),
    desktop-size: var(--f-huge_desktop),
  ),
  title: (
    font-size: var(--f-title),
    font-weight: var(--fw-black),
    line-height: var(--line-height-tightest),
    margin-bottom: var(--spacing-tight),
    desktop-size: var(--f-title_desktop),
  ),
  subtitle: (
    font-size: var(--f-subtitle),
    font-weight: var(--fw-black),
    line-height: var(--line-height-tighter),
    margin-bottom: var(--spacing-more_slight_tight),
    desktop-size: var(--f-subtitle_desktop),
  ),
  subhead: (
    font-size: var(--f-subhead),
    font-weight: var(--fw-semi_bold),
    line-height: var(--line-height-tight),
    margin-bottom: var(--spacing-tight),
    desktop-size: var(--f-subhead_desktop),
  ),
  section_header: (
    font-size: var(--f-section_header),
    font-weight: var(--fw-black),
    margin-bottom: var(--spacing-tight),
    line-height: var(--line-height-tighter),
    desktop-size: var(--f-section_header_desktop),
  ),
  group_header: (
    font-size: var(--f-group_header),
    font-weight: var(--fw-bold),
    line-height: var(--line-height-tighter),
    margin-bottom: var(--spacing-normal),
    desktop-size: var(--f-group_header_desktop),
  ),
  feature_paragraph: (
    font-size: var(--f-feature_paragraph),
    font-weight: var(--fw-regular),
    line-height: var(--line-height-tight),
    margin-bottom: var(--spacing-normal),
    desktop-size: var(--f-feature_paragraph_desktop),
  ),
  paragraph_large: (
    font-size: var(--f-paragraph_large),
    font-weight: var(--fw-regular),
    margin-bottom: var(--spacing-relaxed),
    desktop-size: var(--f-paragraph_large),
  ),
  paragraph_normal: (
    font-size: var(--f-normal),
    font-weight: var(--fw-regular),
    margin-bottom: var(--spacing-relaxed),
    desktop-size: var(--f-normal),
  ),
  paragraph_small: (
    font-size: var(--f-small),
    font-weight: var(--fw-regular),
    margin-bottom: var(--spacing-relaxed),
    desktop-size: var(--f-small),
  ),
  paragraph_xsmall: (
    font-size: var(--f-xsmall),
    margin-bottom: var(--spacing-relaxed),
    desktop-size: var(--f-xsmall),
  ),
);

$weights: (
  black: var(--fw-black),
  bold: var(--fw-bold),
  semi_bold: var(--fw-semi_bold),
  normal: var(--fw-regular),
);

$lineHeights: (
  tightest: var(--line-height-tightest),
  tighter: var(--line-height-tighter),
  tight: var(--line-height-tight),
  normal: var(--line-height-normal),
  slight_relax: var(--line-height-slight_relax),
  relaxed: var(--line-height-relaxed),
);

$alignments: ("left", "right", "center");

$colours: (
  currentColor: currentColor,
  lc_red: var(--lc_red),
  shark: var(--shark),
  white: var(--white),
  black: var(--black),
  gray: var(--gray),
  grayscale: var(--grayscale),
  light-grayscale: var(--light-grayscale),
  medium_gray: var(--medium_gray),
  light_gray: var(--light_gray),
  lighter_gray: var(--lighter-gray),
  lightest_gray: var(--lightest_gray),
  blue: var(--blue),
  blue-bg-light: var(--blue-bg-light),
  prayerful_purple: var(--prayerful_purple),
  social_blues: var(--social_blues),
  sunshine: var(--sunshine),
  red_again: var(--red_again),
  made_new_blue: var(--made_new_blue),
  giving_green: var(--giving_green),
  serving_blue: var(--serving_blue),
  next_steps: var(--next_steps),
  black-overlay-90: var(--black-overlay-90),
  black-overlay-50: var(--black-overlay-50),
  black-overlay-60: var(--black-overlay-60),
  new-here: var(--new-here),
  design-note-pink: var(--design-note-pink),
  life-groups: var(--life-groups),
  life-kids-1: var(--life-kids-1),
  life-kids-2: var(--life-kids-2),
  life-missions: var(--life-missions),
  local-missions: var(--local-missions),
  open-network: var(--open-network),
  sisters: var(--sisters),
  gold2: var(--gold2),
  gray-80: var(--gray-80),
  gray-50: var(--gray-50),
  gray-30: var(--gray-30),
  gray-10: var(--gray-10),
  gray-5: var(--gray-5),
  yellow: var(--yellow),

);

$transparent-colours: (
  lightest_gray: var(--lightest_gray-transparent),
);

$shadows: (
  s: var(--shadow-sm),
  m: var(--shadow-m),
  l: var(--shadow-l),
  xl: var(--shadow-xl),
);

$displays: ("block", "flex", "inline-block", "none");

$flexDirections: ("row", "row-reverse", "column", "column-reverse");

$flexPositions: (
  "stretch",
  "space-between",
  "space-around",
  "flex-end",
  "flex-start",
  "center"
);

$flexGaps: ("row", "column");

$borderRadius: (
  xs: var(--radius-xs),
  s: var(--radius-s),
  m: var(--radius-m),
  l: var(--radius-l),
);

$spaceSizes: (
  auto: auto,
  none: var(--spacing-none),
  tightest: var(--spacing-tightest),
  even_tighter: var(--space-even_tighter),
  tighter: var(--spacing-tighter),
  slight_tight: var(--spacing-slight_tight),
  more_slight_tight: var(--spacing-more_slight_tight),
  tight: var(--spacing-tight),
  normal: var(--spacing-normal),
  slight_relax: var(--spacing-slight_relax),
  kinda_relax: var(--spacing-kinda_relax),
  relaxed: var(--spacing-relaxed),
  bit_more_relaxed: var(--spacing-bit_more_relaxed),
  more_relaxed: var(--spacing-more_relaxed),
  slightly_more_relaxed: var(--spacing-slightly_more_relaxed),
  slightly_abit_more_relaxed: var(--spacing-slightly_abit_more_relaxed),
  very_relaxed: var(--spacing-very_relaxed),
  even_more_relaxed: var(--spacing-even_more_relaxed),
  even_extra_more_relaxed: var(--spacing-even_extra_more_relaxed),
  a_little_relaxed: var(--spacing-a_little_relaxed),
  so_relaxed: var(--spacing-so_relaxed),
  extreme_relax: var(--spacing-extreme_relax),
  most_relaxed: var(--spacing-most_relaxed),
);

$spaceType: (
  p: padding,
  m: margin,
);

$spaceDirection: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);

// values from _variables.scss
$breakpoints: (
  tablet: $md,
  laptop: $lg,
  desktop: $xl,
);

$maxWidthSizes: (
  500,
  640,
  768,
  1026
);


// COLOURS
// hovers background text
@each $colourname, $colour in $colours {
  .text-#{$colourname} {
    color: $colour !important;
  }

  .bg-#{$colourname} {
    background-color: $colour !important;
  }

  .border-colour-#{$colourname} {
    background-color: $colour !important;
  }

  .hover-bg-#{$colourname}:hover,
  .hover-bg-#{$colourname}:focus {
    background-color: $colour !important;
  }

  .hover-text-#{$colourname}:hover,
  .hover-text-#{$colourname}:focus {
    color: $colour !important;
  }
}

// TYPOGRAPHY
// include margin bottom, font size, desktop size

@each $fontLevel, $attributes in $typography {
  .text-#{$fontLevel} {
    @each $attribute, $value in $attributes {
      @if $attribute == "desktop-size" {
        @media screen and (min-width: 640px) {
          font-size: $value !important;
        }
      } @else {
        #{$attribute}: $value !important;
      }
    }
  }
}

// WEIGHTS
@each $weight, $weightValue in $weights {
  .text-weight-#{$weight} {
    font-weight: $weightValue !important;
  }
}

// LINEHEIGHTS
@each $heightName, $value in $lineHeights {
  .line-height-#{$heightName} {
    line-height: $value !important;
  }
}

// STYLES
.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.hover-underline:hover,
.hover-underline:focus {
  text-decoration: underline;
}

.text-upper {
  text-transform: uppercase;
}

.text-lower {
  text-transform: lowercase;
}

@each $alignment in $alignments {
  .text-#{$alignment} {
    text-align: #{$alignment};
  }
}

// SHADOWS
@each $shadowName, $value in $shadows {
  .shadow-#{$shadowName} {
    box-shadow: $value;
  }
}

// GRADIENTS
// every colour to transparent
// any colour to any colour
// t-b, b-t, right-left, left-right
@each $colourName, $colour in $colours {
  .gradient-#{$colourName}-transparent-tb {
    background: linear-gradient(to bottom, $colour, rgba(0, 0, 0, 0));
    opacity: 0.7;
  }

  @if $colourName == "lightest_gray" {
    .gradient-#{$colourName}-transparent-bt {
      background: linear-gradient(to top, $colour, rgba(248,248,248,1));
      opacity: 0.7;
    }
  } @else {
    .gradient-#{$colourName}-transparent-bt {
      background: linear-gradient(to top, $colour, transparent);
      opacity: 0.7;
    }
  }



  .gradient-#{$colourName}-transparent-rl {
    background: linear-gradient(to left, $colour, rgba(0, 0, 0, 0));
    opacity: 0.7;
  }

  .gradient-#{$colourName}-transparent-lr {
    background: linear-gradient(to right, $colour, rgba(0, 0, 0, 0));
    opacity: 0.7;
  }

  .gradient-transparent-#{$colourName}-tb {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), $colour);
    opacity: 0.7;
  }

  .gradient-transparent-#{$colourName}-bt {
    background: linear-gradient(to top, rgba(0, 0, 0, 0), $colour);
    opacity: 0.7;
  }

  .gradient-transparent-#{$colourName}-rl {
    background: linear-gradient(to left, rgba(0, 0, 0, 0), $colour);
    opacity: 0.7;
  }

  .gradient-transparent-#{$colourName}-lr {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), $colour);
    opacity: 0.7;
  }

  @each $secColourName, $secColour in $colours {
    .gradient-#{$colourName}-#{$secColourName}-tb {
      background: linear-gradient(to bottom, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-bt {
      background: linear-gradient(to top, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-rl {
      background: linear-gradient(to left, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-lr {
      background: linear-gradient(to right, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-fifty-r {
      background: linear-gradient(-90deg, $colour 50%, $secColour 50%);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-fifty-l {
      background: linear-gradient(90deg, $colour 50%, $secColour 50%);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-45deg {
      background: linear-gradient(45deg, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-135deg {
      background: linear-gradient(135deg, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-225deg {
      background: linear-gradient(225deg, $colour, $secColour);
      opacity: 0.7;
    }

    .gradient-#{$colourName}-#{$secColourName}-315deg {
      background: linear-gradient(225deg, $colour, $secColour);
      opacity: 0.7;
    }
  }
}

// BORDER RADIUS
@each $size, $value in $borderRadius {
  .border-radius-#{$size} {
    border-radius: $value;
  }
}

// SPACING
// margin, padding
// x, y, top, bottom, l, r
@each $spacetype, $spacevalue in $spaceType {
    @each $spacesize, $sizevalue in $spaceSizes {
      .#{$spacetype}-#{$spacesize} {
        #{$spacevalue}: $sizevalue !important;
      }

      .#{$spacetype}x-#{$spacesize} {
        #{$spacevalue}-left: $sizevalue !important;
        #{$spacevalue}-right: $sizevalue !important;
      }

      .#{$spacetype}y-#{$spacesize} {
        #{$spacevalue}-top: $sizevalue !important;
        #{$spacevalue}-bottom: $sizevalue !important;
      }

      @each $spacedir, $dir in $spaceDirection {
        .#{$spacetype}#{$spacedir}-#{$spacesize} {
          #{$spacevalue}-#{$dir}: $sizevalue !important;
        }
      }
    }
  }

  // DISPLAY
  // flex, block, inline
  @each $display in $displays {
    .display-#{$display} {
      display: #{$display} !important;
    }
  }

  // hide in breakpoints
  @each $breakpoint, $value in $breakpoints {
    .#{$breakpoint}-hide {
      @media screen and (min-width: #{$value}) {
        display: none;
      }
    }
    @media screen and (min-width: #{$value}){
      @each $spacetype, $spacevalue in $spaceType {
        @each $spacesize, $sizevalue in $spaceSizes {
          .#{$spacetype}-#{$breakpoint}-#{$spacesize} {
            #{$spacevalue}: $sizevalue !important;
          }

          .#{$spacetype}x-#{$breakpoint}-#{$spacesize} {
            #{$spacevalue}-left: $sizevalue !important;
            #{$spacevalue}-right: $sizevalue !important;
          }

          .#{$spacetype}y-#{$breakpoint}-#{$spacesize} {
            #{$spacevalue}-top: $sizevalue !important;
            #{$spacevalue}-bottom: $sizevalue !important;
          }

          @each $spacedir, $dir in $spaceDirection {
            .#{$spacetype}#{$spacedir}-#{$breakpoint}-#{$spacesize} {
              #{$spacevalue}-#{$dir}: $sizevalue !important;
            }
          }
        }
      }
    }
  }

// FLEX HELPERS
// directions
@each $direction in $flexDirections {
  .flex-#{$direction} {
    flex-direction: #{$direction};
  }
}

// flex directions with breakpoints i.e. "md-flex-row"
@each $sizeName, $sizeValue in (md: map-get($breakpoints,'tablet'), lg: map-get($breakpoints,'laptop')) {
  @media screen and (min-width: #{$sizeValue}){
    @each $direction in ("row", "column") {
      .#{$sizeName}-flex-#{$direction} {
        flex-direction: #{$direction};
      }
    }
  }
}

// positions
@each $flexPosition in $flexPositions {
  .justify-#{$flexPosition} {
    justify-content: #{$flexPosition};
  }

  .align-#{$flexPosition} {
    align-items: #{$flexPosition};
  }
}

// wrap
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: no-wrap;
}

// general gap i.e. "gap-normal"
@each $spacesize, $sizevalue in $spaceSizes {
  .gap-#{$spacesize} {
    gap: #{$sizevalue};
  }
}

// gap for row and column i.e. "row-gap-normal"
@each $flexgap in $flexGaps {
  @each $spacesize, $sizevalue in $spaceSizes {
      .#{$flexgap}-gap-#{$spacesize} {
          #{$flexgap}-gap: #{$sizevalue};
      }
  }
}

.position-relative {
  position: relative
}

// MAX-WIDTHs
@each $maxWidthSize in $maxWidthSizes {
  .maxWidth-#{$maxWidthSize} {
    max-width: #{$maxWidthSize}px !important;
  }
}
