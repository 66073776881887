@import './_font.scss';
@import './_variables.scss';
@import './_helper.scss';
@import './_icons.scss';
@import './_hubspot.scss';
@import './_forms.scss';
@import './reset';

html {
  background-color: $black2;
  height: 100%;
  min-height: 100%;
}
body {
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  min-height: 100%;
  padding-bottom: env(safe-area-inset-bottom);

  .bg-dark {
    background-color: $black2;
  }

  .bg-gold {
    background-color: $gold;
  }

  .bg-gray {
    background-color: $gray !important;
  }

  .bg-cream {
    background-color: $cream;

    a {
      color: $gold;
    }
  }
}

main {
  padding-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
  font-family: var(--font-family-editor);
  font-weight: 500;
}

.text-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--white);
  }

  p {
    color: var(--gray-10);
  }
}

p {
  color: var(--gray);
  font-weight: 600;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding-left: 0;
  padding-right: 0;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: $yellow;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  appearance: none;
}

.container {
  max-width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (min-width: $md) {
    margin-left: auto;
    margin-right: auto;
    max-width: $contentMaxWidth;
  }
  &.narrow {
    @media screen and (min-width: $md) {
      max-width: $contentMaxWidthNarrow;
    }
  }

  .container {
    max-width: 100%;
  }
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.has-bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgixBG.dual-contentzone.bg-repeat,
.imgixBG.single-contentzone.bg-repeat {
  background-repeat: repeat;
  background-size: contain !important; // This overrides 'background-size: cover' that comes from imgix.
  width: initial;
}

.display-none-mobile {
  @media screen and (max-width: $md) {
    display: none;
  }
}

p.content-text + div.button-group-wrapper > ul > div {
  margin-top: 0px;
}

.disabled-message-banner {
  align-items: center;
  box-shadow: 0 2px 6px rgba(45, 45, 46, 0.25);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 16px 12px;
  position: relative;
  text-align: center;
  z-index: 20;
}
.zoom-effect {
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    transform: scale(1.03);
    z-index: 100;
  }
}

.tag {
  background-color: $gold;
  border-radius: 7px;
  color: $yellow;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2.5px;
  line-height: 15px;
  margin-right: 10px;
  opacity: 0.7;
  padding: 4px 16px;
  text-align: center;
  text-transform: uppercase;
}

div#root {
  height: 100%;
  min-height: 100%;

  > div {
    height: 100%;
    min-height: 100%;

    .Basic {
      background-color: $black2;
      height: 100%;
      min-height: 100%;
    }
  }
}

.fadeInOut {
  transition: width 0.3s ease-in, opacity, visibility;

  &.hidden {
    opacity: 0;
    transition: width 0.3s ease-out, opacity, visibility;
    visibility: hidden;
    width: 0px;
  }
}

.hidden-content {
  display: none !important;
  height: 0px;
}

.card-wrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

a.card-wrapper:hover {
  text-decoration: none;

  a {
    color: $yellow;
    text-decoration: underline;
  }
}
