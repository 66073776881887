$contentMaxWidthWide: 1400px;
$contentMaxWidth: 1400px;
$contentMaxWidthNarrow: 787px;

// Break point
$xs: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;

// Base
$linkColor: #009ecc;

$black: #000000;
$black2: #191a18;
$yellow: #e6df69;
$gold: #494538;
$gold2: #a48d45;
$white: #fffffe;
$lightGray: #f6f6f6;
$cream: #fefaee;
$gray: #404041;
