@import '../../styles/variables';
.journey {
  .journey-content {
    z-index: 10;
    position: relative;
    top: -50px;
    .journey-start-heading {
      color: $yellow !important;
      font-size: 22px;
      border-bottom: 1px solid #ffffff40;
    }
  }
  .journey-bottom{
    .journey-form{
        .embed-item{
            background-color: $yellow;
            .btn-primary{
                background-color: $white;
                border: solid 1px $black;
                &:hover{
                    background-color: $black; 
                }
            }
        }
    }
  }
}

@media screen and (max-width: $md) {
  .journey {
    .journey-content {
      z-index: 10;
      position: relative;
      top: -320px;
      margin-bottom: -300px;
      .container {
        padding: 0;
      }
      .journey-start-heading {
        color: $yellow !important;
        font-size: 22px;
        line-height: 65px;
        margin: 0 20px;
      }
    }
    .journey-bottom{
        > div{
            display: block;
            margin: 20px;
        }
    }
  }
}

@media screen and (min-width: $md) {
  .journey {
    .journey-content {
      z-index: 10;
      position: relative;
      top: -400px;
      margin-bottom: -150px;
      .journey-start-heading {
        color: $yellow !important;
        font-size: 52px;
        line-height: 65px;
        padding-bottom: 30px;
      }
    }
    .journey-bottom{
        position: relative;
        top: -200px;
        display: flex;
        align-items: center;
        > div{
            margin: 20px;
        }
        .journey-form{
            flex: 3;

        }
        .journey-featured{
            flex: 2;
            min-width: 350px;
        }
    }
  }
}
