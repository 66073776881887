@import '../../styles/variables';

.tagged-image-wrapper {
  margin: 1em auto;

  .tagged-image {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .full-width-tagged-image {
    box-sizing: border-box;
    margin: 0 auto;
  }

  .full-width-image-wrapper {
    border-radius: 7px;
  }

  .image {
    border-radius: 7px;
    height: 100%;
  }

  .tagged-image-title {
    font-weight: 500;
    margin: 10px 0 0;
  }

  .article-title {
    font-weight: 500;
    margin: 10px 0 0;
  }

  .search-result-title {
    font-weight: 500;
    letter-spacing: 0em;
    margin: 10px 0;
  }

  .tagged-image-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 10px;
    opacity: 0.6;
  }

  .search-result-text {
    font-weight: 400;
    letter-spacing: 0em;
    margin-top: 0;
  }

  .card-author-duration {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 17px;
    opacity: 0.6;
  }

  .tag-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .card-title a:hover {
    color: $yellow;
  }
}

@media screen and (max-width: $md) {
  .tagged-image-wrapper {
    .tagged-image {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .full-width-tagged-image {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .image-wrapper {
      margin-bottom: 20px;
      width: 100%;

      .image {
        height: fit-content;
        object-fit: cover;
        width: 100%;
      }
    }

    .full-width-image-wrapper {
      margin-bottom: 20px;

      .image {
        width: 100%;
      }
    }

    .tagged-image-title {
      font-size: 32px;
      line-height: 40px;
    }

    .search-result-title,
    .article-title {
      font-size: 22px;
      line-height: 30px;
    }

    .article-title {
      font-size: 22px;
      line-height: 30px;
    }

    .search-result-text {
      font-size: 14px;
      line-height: 15px;
    }
  }
}

@media screen and (min-width: $md) {
  .tagged-image-wrapper {
    max-width: 787px;

    .tagged-image {
      display: flex;
      flex-direction: row;
    }

    .full-width-tagged-image {
      display: flex;
      flex-direction: column;

      .content-wrapper {
        width: unset;
      }
    }

    .image-wrapper {
      margin-right: 30px;
      max-width: 325px;
      width: 100%;

      .image {
        border-radius: 7px;
        height: fit-content;
        max-width: 325px;
        min-height: 150px;
        object-fit: contain;
        width: 100%;
      }
    }

    .content-wrapper {
      width: 415px;

      &.search-result {
        width: 220px;
      }
    }

    .full-width-image-wrapper {
      margin-bottom: 30px;
      max-width: unset;
      min-width: 320px;

      .image {
        width: 100%;
      }
    }

    .tagged-image-title,
    .article-title {
      font-size: 32px;
      line-height: 35px;
    }

    .search-result-title {
      font-size: 32px;
      line-height: 40px;
    }

    .search-result-text {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.dark-mode {
  .card-title a,
  .card-title span {
    color: $white;
  }

  .tagged-image-text {
    color: $lightGray;
  }

  .search-result-text {
    color: $lightGray;
  }

  .card-author-duration {
    color: $lightGray;
  }

  .tag {
    background-color: #494538;
    color: #e6df69;
  }
}

.light-mode {
  .card-title a,
  .card-title span {
    color: $gray;
  }

  .tagged-image-text {
    color: $gray;
  }

  .search-result-text {
    color: $gray;
  }

  .card-author-duration {
    color: $gray;
  }

  .tag {
    background-color: #f0edc0;
    color: $gray;
  }
}

.card-wrapper {
  position: relative;

  &.clickable-card {
    cursor: pointer;
  }

  &:hover:not(:has(.tag-wrapper:hover)) {
    .card-title a,
    .card-title span {
      color: $yellow;
      text-decoration: underline;
    }
  }

  .card-main-link {
    inset: 0;
    position: absolute;
  }
}
