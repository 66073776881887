@import '../../styles/variables';

.search-wrapper {
  background-color: $cream;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;

  .close-button-wrapper {
    display: flex;

    .action-button {
      margin-left: auto;
      margin-right: 24px;
    }
  }

  .contents-container {
    margin: 0 auto;
    max-width: 910px;

    .title {
      color: $gray;
      font-weight: 500;
      text-align: center;
    }

    .flex-content {
      display: flex;
    }

    .searched-contents {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      .card-wrapper {
        margin-left: 0px;
      }
    }

    .no-results-wrapper {
      > p {
        margin: 0;
        border-bottom: 1px solid #3a3b3934;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }
    }
  }

  .load-button-wrapper {
    display: flex;

    .action-button {
      margin: 0 auto;
    }
  }

  .action-button {
    border: 2px solid $black2;
    border-radius: 100px;
    color: $black2;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 17px;
    padding: 5px 20px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: $md) {
  .search-wrapper {
    padding: 20px 20px 80px 20px;

    .contents-container {
      .title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .flex-content {
        flex-direction: column;

        .input {
          font-size: 14px;
        }
      }

      .searched-contents {
        gap: 30px;
        padding: 32px 0 40px 0;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .search-wrapper {
    padding: 20px 20px 80px 20px;

    .contents-container {
      .title {
        font-size: 42px;
        line-height: 67px;
        margin-bottom: 35px;
      }

      .flex-content {
        flex-direction: row;

        .input {
          font-size: 16px;
        }
      }

      .searched-contents {
        gap: 60px;
        padding: 32px 0 80px 0;

        .tagged-image-wrapper {
          margin-left: 0px;

          .content-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
