@import '../../styles/variables';

.big-journey-card-wrapper {
  cursor: inherit;
  margin: 0 5px;
  display: inline-block;
  position: relative;
  &.disable-click{
    pointer-events: none;
  }
  .big-journey-card-container {
    transition: border 150ms, background-color 150ms;
    position: relative;
    border: 5px solid transparent;
    border-radius: 5px;
    .card-part {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0.2em;
      text-align: center;
      color: $yellow;
      text-transform: uppercase;
      margin: 10px 0;
    }
    .image-container {
      width: 100%;
      position: relative;
      .gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        background: #3d383466;
        background-image: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.9) 100%
        );
      }
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 7px;
      }
    }
  }
  .icon {
    width: 48px;
    height: 48px;
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .big-journey-card-content {
    position: absolute;
    bottom: 25px;
    left: 20px;
    margin-top: auto;
    .tag-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      row-gap: 8px;
      z-index: 2;

      .tag {
        background-color: #494538;
        color: $yellow;
      }
    }
    .title {
      color: $white;
      font-weight: 500;
      margin: 20px 0 15px 0;
      white-space: pre-wrap;
    }
    .author-duration {
      color: #fbf9f6;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      opacity: 0.6;
    }
    .start-button {
      display: none;
      transition: margin 800ms;
      margin: 30px auto 0 auto;
      width: 100%;
    }
  }
  .big-journey-card-container:hover {
    background-color: $yellow;
    border-radius: 7px;
    border: 5px solid $yellow;
    .start-button {
      display: flex;
      position: relative;
      z-index: 2;
    }
    .card-part {
      color: $black;
    }
  }

  .card-main-link {
    inset: 0;
    position: absolute;
    z-index: 1;

    &:hover {
      +.big-journey-card-container {
        background-color: $yellow;
        border-radius: 7px;
        border: 5px solid $yellow;

        .start-button {
          display: flex;
          position: relative;
          z-index: 2;
        }

        .card-part {
          color: $black;
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .big-journey-card-container {
    position: relative;
    width: 226px;
    height: 400px;
    .image-container {
      height: 364px;
    }
  }
  .big-journey-card-content {
    max-width: 185px;
    .title {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

@media screen and (min-width: $md) {
  .big-journey-card-container {
    position: relative;
    width: 300px;
    height: 556px;
    .image-container {
      height: 519px;
    }
  }
  .big-journey-card-content {
    max-width: 250px;
    .title {
      font-size: 30px;
      line-height: 35px;
    }
  }
}
