@import './_variables.scss';

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?sw4vod');
  src: url('../fonts/icomoon.eot?sw4vod#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?sw4vod') format('truetype'),
    url('../fonts/icomoon.woff?sw4vod') format('woff'),
    url('../fonts/icomoon.woff2?sw4vod') format('woff2'),
    url('../fonts/finds-icomoon.svg?sw4vod#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-as-bg {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
  height: 2rem;
  width: 2rem;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-link {
  .icon {
    display: inline-block;
    transition: all 0.3s ease-in-out;

    .path1,
    .path2,
    .path3,
    .path4,
    .path5,
    .path6 {
      &:before {
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.icon-bars {
  &:before {
    content: '\e900';
  }
}
.icon-download {
  &:before {
    content: '\e901';
  }
}
.icon-close {
  &:before {
    content: '\e962';
  }
}
.icon-external-link {
  &:before {
    content: '\e903';
  }
}
.icon-heart {
  &:before {
    content: '\e904';
  }
}
.icon-search {
  &:before {
    content: '\e905';
  }
}
.icon-share {
  &:before {
    content: '\e906';
  }
}
.icon-play {
  &:before {
    content: '\e907';
  }
}
.icon-vertical-ellipses {
  &:before {
    content: '\e908';
  }
}
.icon-globe {
  &:before {
    content: '\e909';
  }
}
.icon-baptism {
  &:before {
    content: '\e956';
  }
}
.icon-communication {
  &:before {
    content: '\e957';
  }
}
.icon-giving {
  &:before {
    content: '\e958';
  }
}
.icon-life-groups {
  &:before {
    content: '\e959';
  }
}
.icon-new {
  &:before {
    content: '\e95a';
  }
}
.icon-open-door {
  &:before {
    content: '\e95b';
  }
}
.icon-prayer {
  &:before {
    content: '\e95c';
  }
}
.icon-raised-hand {
  &:before {
    content: '\e95d';
  }
}
.icon-renewed-heart {
  &:before {
    content: '\e95e';
  }
}
.icon-serving {
  &:before {
    content: '\e95f';
  }
}
.icon-tiktok {
  .path1 {
    &:before {
      content: '\e978';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e979';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e97a';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        content: '\e978';
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        content: '\e979';
        margin-left: -1em;
        color: $gray;
        opacity: 0.5;
      }
    }
    .path3 {
      &:before {
        content: '\e97a';
        margin-left: -1em;
        color: $gray;
      }
    }
  }
}

.dark-mode .icon-tiktok {
  .path1 {
    &:before {
      content: '\e976';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e977';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}

.dark-mode .icon-youtube {
  .path1 {
    &:before {
      content: '\e930';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e931';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-twitter-x {
  background-color: rgb(64, 64, 65);
}
.dark-mode .icon-twitter {
  .path1 {
    &:before {
      content: '\e90a';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e914';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-pinterest {
  .path1 {
    &:before {
      content: '\e90b';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e955';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-linkedin {
  .path1 {
    &:before {
      content: '\e90c';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e960';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-facebook {
  .path1 {
    &:before {
      content: '\e90d';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e961';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-instagram {
  .path1 {
    &:before {
      content: '\e969';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e96a';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
  .path3 {
    &:before {
      content: '\e96b';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
  .path4 {
    &:before {
      content: '\e970';
      margin-left: -1.0166015625em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-email {
  .path1 {
    &:before {
      content: '\e90f';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e963';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-share-link {
  .path1 {
    &:before {
      content: '\e910';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e964';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-location {
  .path1 {
    &:before {
      content: '\e911';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e965';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.dark-mode .icon-spotify {
  .path1 {
    &:before {
      content: '\e912';
      color: rgb(64, 64, 65);
    }
  }
  .path2 {
    &:before {
      content: '\e966';
      margin-left: -1em;
      color: rgb(255, 255, 255);
    }
  }
}
.icon-youtube {
  .path1 {
    &:before {
      content: '\e913';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e967';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e968';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-twitter-x {
  background-color: #3a3b39;
  background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.048762 0.640625L7.77054 10.9654L0 19.3598H1.74884L8.55193 12.0104L14.0486 19.3598H20L11.8438 8.45433L19.0765 0.640625H17.3277L11.0624 7.40935L6.00012 0.640625H0.048762ZM2.62056 1.92882H5.35462L17.4278 18.0714H14.6937L2.62056 1.92882Z" fill="%23e6df69"/></svg>');
  background-size: auto !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  color: $yellow;
  height: 20px;
  padding: 18px;
  width: 20px;

  &:hover {
    background-color: $yellow;
    background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.048762 0.640625L7.77054 10.9654L0 19.3598H1.74884L8.55193 12.0104L14.0486 19.3598H20L11.8438 8.45433L19.0765 0.640625H17.3277L11.0624 7.40935L6.00012 0.640625H0.048762ZM2.62056 1.92882H5.35462L17.4278 18.0714H14.6937L2.62056 1.92882Z" fill="%233a3b39"/></svg>');
    color: $gray;
  }
}
.icon-twitter {
  .path1 {
    &:before {
      content: '\e915';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e916';
      margin-left: -1.015625em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e917';
      margin-left: -1.015625em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-pinterest {
  .path1 {
    &:before {
      content: '\e918';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e919';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e91a';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-linkedin {
  .path1 {
    &:before {
      content: '\e91b';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e91c';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e91d';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-facebook {
  .path1 {
    &:before {
      content: '\e91e';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e91f';
      margin-left: -1.015625em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e920';
      margin-left: -1.015625em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-instagram {
  .path1 {
    &:before {
      content: '\e921';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e922';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e923';
      margin-left: -1em;
      color: $yellow;
    }
  }
  .path4 {
    &:before {
      content: '\e902';
      margin-left: -1em;
      color: $yellow;
    }
  }
  .path5 {
    &:before {
      content: '\e90e';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
    .path4 {
      &:before {
        color: $gray;
      }
    }
    .path5 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-email {
  .path1 {
    &:before {
      content: '\e924';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e925';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e926';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-share-link {
  .path1 {
    &:before {
      content: '\e927';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e928';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e929';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-location {
  .path1 {
    &:before {
      content: '\e92d';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e92e';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e92f';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-spotify {
  .path1 {
    &:before {
      content: '\e92a';
      color: $gray;
    }
  }
  .path2 {
    &:before {
      content: '\e92b';
      margin-left: -1em;
      color: $gray;
      opacity: 0.5;
    }
  }
  .path3 {
    &:before {
      content: '\e92c';
      margin-left: -1em;
      color: $yellow;
    }
  }
  &:hover {
    .path1 {
      &:before {
        color: $yellow;
      }
    }
    .path2 {
      &:before {
        color: $gray;
      }
    }
    .path3 {
      &:before {
        color: $gray;
      }
    }
  }
}
.icon-youtube-color {
  background-image: url('../../src/assets/finds-icon-youtube.svg');
  @include icon-as-bg;
}
.icon-google-podcast {
  background-image: url('../../src/assets/finds-icon-google-podcast.svg');
  @include icon-as-bg;
}
.icon-apple-podcast {
  background-image: url('../../src/assets/finds-icon-apple-podcast.svg');
  @include icon-as-bg;
}
.icon-angle-up {
  &:before {
    content: '\e96c';
  }
}
.icon-angle-right {
  &:before {
    content: '\e96d';
  }
}
.icon-angle-down {
  &:before {
    content: '\e96e';
  }
}
.icon-angle-left {
  &:before {
    content: '\e96f';
  }
}

.icon-slide-left {
  .path1 {
    &:before {
      content: '\e91e';
      color: rgb(255, 255, 255);
    }
  }
  .path2 {
    &:before {
      content: '\e91f';
      margin-left: -1.015625em;
      color: rgb(0, 0, 0);
    }
  }
  .path3 {
    &:before {
      content: '\e96f';
      margin-left: -1.015625em;
      color: rgb(0, 0, 0);
    }
  }
}

.icon-slide-right {
  .path1 {
    &:before {
      content: '\e91e';
      color: rgb(255, 255, 255);
    }
  }
  .path2 {
    &:before {
      content: '\e91f';
      margin-left: -1.015625em;
      color: rgb(0, 0, 0);
    }
  }
  .path3 {
    &:before {
      content: '\e96d';
      margin-left: -1.015625em;
      color: rgb(0, 0, 0);
    }
  }
}

[class*=' icon-channel'] {
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;
  width: 100%;
}

.icon-channel-amazon-music {
  background-image: url('../../src/assets/finds-channel-amazon-music.svg');
}

.icon-channel-amazon-music-black {
  background-image: url('../../src/assets/finds-channel-amazon-music-black.svg');
}

.icon-channel-amazon-music-unlimited {
  background-image: url('../../src/assets/finds-channel-amazon-music-unlimited.svg');
}

.icon-channel-app-store {
  background-image: url('../../src/assets/finds-channel-app-store.svg');
}

.icon-channel-app-store-black {
  background-image: url('../../src/assets/finds-channel-app-store-black.svg');
}

.icon-channel-apple-music {
  background-image: url('../../src/assets/finds-channel-apple-music.svg');
}

.icon-channel-apple-music-black {
  background-image: url('../../src/assets/finds-channel-apple-music-black.svg');
}

.icon-channel-apple-podcasts {
  background-image: url('../../src/assets/finds-channel-apple-podcasts.svg');
}

.icon-channel-apple-podcasts-black {
  background-image: url('../../src/assets/finds-channel-apple-podcasts-black.svg');
}

.icon-channel-google-music {
  background-image: url('../../src/assets/finds-channel-google-music.svg');
}

.icon-channel-google-podcasts {
  background-image: url('../../src/assets/finds-channel-google-podcasts.svg');
}

.icon-channel-itune,
.icon-channel-itunes {
  background-image: url('../../src/assets/finds-channel-itunes.svg');
}

.icon-channel-LC-app-listen {
  background-image: url('../../src/assets/finds-channel-LC-app-listen.svg');
}

.icon-channel-LC-app-listen-black {
  background-image: url('../../src/assets/finds-channel-LC-app-listen-black.svg');
}

.icon-channel-pandora {
  background-image: url('../../src/assets/finds-channel-pandora.svg');
}

.icon-channel-pandora-black {
  background-image: url('../../src/assets/finds-channel-pandora-black.svg');
}

.icon-channel-play-store {
  background-image: url('../../src/assets/finds-channel-play-store.svg');
}

.icon-channel-play-store-black {
  background-image: url('../../src/assets/finds-channel-play-store-black.svg');
}

.icon-channel-spotify {
  background-image: url('../../src/assets/finds-channel-spotify.svg');
}

.icon-channel-spotify-black {
  background-image: url('../../src/assets/finds-channel-spotify-black.svg');
}

.icon-channel-youtube {
  background-image: url('../../src/assets/finds-channel-youtube.svg');
}

.icon-channel-youtube-black {
  background-image: url('../../src/assets/finds-channel-youtube-black.svg');
}

.icon-clock-stroked {
  background-image: url('../../src/assets/finds-icon-clock-stroked.svg');
  @include icon-as-bg;
}

.icon-play-filled {
  background-image: url('../../src/assets/finds-icon-play-filled.svg');
  @include icon-as-bg;
}

.icon-marker-filled {
  background-image: url('../../src/assets/finds-icon-marker-filled.svg');
  @include icon-as-bg;
}

.icon-phone-filled {
  background-image: url('../../src/assets/finds-icon-phone-filled.svg');
  @include icon-as-bg;
}

.icon-instagram-stroked {
  background-image: url('../../src/assets/finds-icon-instagram-stroked.svg');
  @include icon-as-bg;
}

.icon-facebook-circle-filled {
  background-image: url('../../src/assets/finds-icon-facebook-circle-filled.svg');
  @include icon-as-bg;
}
