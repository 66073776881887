@import '../../styles/variables';

.journeys-listing-wrapper {
  margin: 0 auto;
  max-width: $contentMaxWidthWide;
  overflow: hidden;

  .slick-slider {
    margin-left: -25px;
    width: calc(100% + 50px);
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;

    .left-filters {
      border: none;
      display: flex;
      flex-direction: column;
      gap: 14px;
      justify-content: space-between;
      text-transform: capitalize;

      .search-icon {
        cursor: pointer;
        display: inline-block;
        height: 17px;
        margin: auto 20px auto 0;
        padding-bottom: 5px;
        width: 17px;
      }
    }
  }

  .contents {
    display: grid !important;
    gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: calc(100% - 100px);
  }

  .no-results-wrapper {
    > p {
      margin: 0;
      border-bottom: 1px solid #3a3b3934;
      margin-bottom: 32px;
      padding-bottom: 32px;
    }
  }

  .pagination {
    display: flex;
    flex-direction: row;
    margin: 40px auto 0 auto;
    width: 135px;

    .counter {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.25em;
      line-height: 17px;
      margin: auto;
      text-align: center;
    }

    .icon {
      cursor: pointer;
      height: 32px;
      width: 32px;
    }
  }

  &.dark-mode {
    .journeys-listing-title {
      color: $yellow;
    }

    .search-result-text {
      color: $white;
    }
    .no-results-wrapper {
      > p {
        color: $white;
      }
    }

    .filters {
      .search-tag-filters {
        .tag-filter {
          color: #A3A3A2;

          &.active-tag {
            color: $yellow;
          }

          &:hover:not(.active-tag) {
            color: $yellow;
          }
        }
      }
    }

    .pagination {
      .counter {
        color: $white;
      }
    }
  }

  &.light-mode {
    .journeys-listing-title {
      color: $gray;
    }

    .search-result-text {
      color: $white;
    }

    .no-results-wrapper {
      > p {
        color: $white;
      }
    }


    .filters {
      .search-tag-filters {
        .tag-filter {
          color: #494538;

          &.active-tag {
            color: $yellow;
          }

          &:hover:not(.active-tag) {
            color: $yellow;
          }
        }
      }
    }

    .pagination {
      .counter {
        color: $gray;
      }
    }
  }

}

@media screen and (max-width: $md) {
  .journeys-listing-wrapper {
    padding: 20px 20px 40px 20px;

    .journeys-listing-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 67px;
      margin: -20px 0 10px 0;
    }

    .filters {
      flex-direction: column;

      .left-filters {
        border-bottom: none;
        flex-direction: column;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
      }
    }

    .search-result-text {
      display: none;
    }

    .contents {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: 90%;
      padding: 0 20px 0 20px;
    }

    &.dark-mode {
      .search-input-wrapper {
        border-bottom: 1px solid #ffffff36;
      }
    }

    &.light-mode {
      .search-input-wrapper {
        border-bottom: 1px solid #3a3b3934;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .journeys-listing-wrapper {
    padding: 20px 40px 100px 40px;

    .slick-slider {
      margin-left: -100px;
      width: calc(100% + 200px);
    }

    .journeys-listing-title {
      font-size: 42px;
      font-weight: 500;
      line-height: 67px;
      margin: 30px 0 25px 0;
    }

    .search-result-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 30px;

      .clear-result-text {
        cursor: pointer;
        font-weight: 700;
        margin-left: 10px;
        text-decoration: underline;
      }
    }

    .contents {
      cursor: pointer;
      display: grid;
      gap: 25px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin-left: -10px;
      max-width: $contentMaxWidthWide;
      padding: 33px 100px 40px 100px;

      .image-with-parts-content {
        cursor: pointer;
      }

      .tagged-image-wrapper {
        margin: 5px;
      }
    }

    .filters {
      .left-filters {
        flex-direction: row;
      }

      .search-input-wrapper {
        border-bottom: none;
      }
    }

    &.dark-mode {
      .left-filters  {
        border-bottom: 1px solid #ffffff36;
      }

      .no-results-wrapper {
        > p {
          border-bottom-color: #ffffff36;
        }
      }
    }

    &.light-mode {
      .left-filters  {
        border-bottom: 1px solid #3a3b3934;
      }

      .no-results-wrapper {
        > p {
          border-bottom-color: #3a3b3934;
        }
      }
    }
  }
}
