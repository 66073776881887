@import '../../styles/variables';

.journey-details-hero-wrapper {
  height: 395px;
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  .header-container {
    background-color: $cream;
    display: flex;
    height: 56px;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    .container {
      box-sizing: border-box;
      display: flex;
      flex: 1;
    }

    .icon {
      height: 25px;
      margin: 12px 15px 15px 15px;
      width: 25px;
    }

    .text {
      color: $black2;
      font-family: var(--font-family-editor);
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin: auto 0;
    }

    .gold-text {
      color: $gold2;
      font-family: var(--font-family-editor);
      font-weight: 500;
    }
  }

  .background-gradient {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .background-image {
    display: block;
    object-fit: cover;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  .content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 50%;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 10;

    .bottom-content {
      margin-top: auto;

      .tag-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 8px;

        .tag {
          background-color: #494538;
          color: $yellow;
        }
      }

      .title {
        font-weight: 500;
        letter-spacing: 0;
        margin: 10px 0 0 0;
        max-width: 992px;
      }

      .author-duration {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0em;
        line-height: 17px;
        opacity: 0.6;
      }

      .parts-tag {
        background-color: #100f0e;
        border-radius: 50px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        padding: 2px 15px;
        text-align: center;
      }

      .article-navigation {
        &.border-bottom {
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: $md) {
  .journey-details-hero-wrapper {
    height: 395px;
    padding-bottom: 200px;

    .background-gradient {
      height: 435px;
    }

    .background-image {
      height: 432px;
    }

    .header-container {
      height: 52px;
      padding: 10px 0px;
      width: -webkit-fill-available;

      .icon {
        height: 32px;
        margin: 10px;
        width: 32px;
      }

      .text {
        font-size: 18px;
        font-weight: 500;
        line-height: 25px;
      }

      .gold-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        margin: auto 20px;
      }
    }

    .breadcrumb {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 16px;
      margin-top: 80px;
      padding: 0 0 0 30px;

      .yellow {
        color: $yellow;
        font-weight: 700;
      }
    }

    .content-wrapper {
      .bottom-content {

        .tag-wrapper {
          margin-top: -20px;
        }

        .title {
          font-size: 32px;
          line-height: 44px;
        }

        .author-duration {
          margin: 20px 0;
        }

        .article-navigation {
          margin-top: 85px;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .journey-details-hero-wrapper {
    height: 550px;

    .background-gradient {
      height: 100%;
    }

    .background-image {
      height: 100%;
    }

    .header-container {
      padding: 10px 0px;
      width: -webkit-fill-available;

      .icon {
        height: 32px;
        width: 32px;
      }

      .text {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
      }

      .gold-text {
        font-size: 22px;
        line-height: 28px;
        margin: auto 10px;
      }
    }

    .breadcrumb {
      display: none;
    }

    .content-wrapper {
      .bottom-content {
        padding: 0 40px;

        .title {
          font-size: 62px;
          line-height: 75px;
        }

        .author-duration {
          margin-top: 5px;
        }

        .article-navigation {
          margin-top: 30px;

          &.border-bottom {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .journey-details-hero-wrapper {
    .header-container {
      padding: 10px 20px;
    }
  }
}

.journey-details-hero-wrapper.gradient-dark {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $black2);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}

.journey-details-hero-wrapper.gradient-cream {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $cream);
  }

  .title {
    color: $black !important;
  }

  .author-duration {
    color: $gray;
  }
}

.journey-details-hero-wrapper.gradient-gold {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gold);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}

.journey-details-hero-wrapper.gradient-gray {
  .background-gradient {
    background: linear-gradient(180deg, transparent, $gray);
  }

  .title {
    color: $white !important;
  }

  .author-duration {
    color: $lightGray;
    opacity: 60%;
  }
}
