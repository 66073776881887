@import '../../styles/variables';

.share-wrapper {
  width: 100%;

  .subtitle {
    color: $yellow;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.25em;
    line-height: 15px;
    margin: 0 0 16px 0;
    text-transform: uppercase;
  }
}

.social-media-links {
  display: flex;
  gap: 8px;

  .social-link {
    display: inline-block;

    &:hover {
      text-decoration: none !important;
    }

    .icon {
      font-size: 56px;
      margin: 0;
    }
  }
}

@media screen and (min-width: $md) {
  .social-media-links {
    margin: 0 0 0 auto;
  }
}
