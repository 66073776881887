@import '../../styles/variables';

.modal-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.6s;
  visibility: hidden;
  width: 100vw;
  z-index: -1;

  .embed-item {
    margin: 0 auto;
    padding-bottom: 32px;
    padding-top: 32px;
  }

  &.show {
    margin: 0px !important;
    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    visibility: visible;
    z-index: 12;

    &.inactive {
      opacity: 0;
      visibility: hidden;
    }

    &.modal-dev-mode {
      z-index: 70000;
    }
  }

  .modal {
    background-color: white;
    margin: 16px;
    overflow: auto;
    position: relative;
    width: calc(100% - 32px);

    .rich-text {
      padding: 0;
    }

    iframe {
      width: 100%;
    }
  }

  .close-wrapper {
    background-color: var(--grayscale);
    cursor: pointer;
    font-size: 1.5rem;
    height: 32px;
    width: 100vw;

    .close-button {
      color: #ececec;
    }
  }

  &.infoModal {
    .modal-content {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;

      .featured-image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        margin: 0;
        overflow: hidden;
        padding-top: 50%;
        width: 100%;
      }

      .modal-heading {
        font-size: 24px;
        line-height: 160%;
        margin-bottom: 0;
        margin-top: 24px;
      }

      .modal-sub-heading {
        margin-top: 0;
        line-height: 160%;
      }

      .modal-button {
        align-items: flex-end;
        display: flex;
        
        &.button-align-left{
            justify-content: flex-start;
        }
        &.button-align-center{
            justify-content: center;
        }
        &.button-align-right{
            justify-content: flex-end;
        }

        .btn {
          margin-bottom: 12px;

          @media screen and (max-width: $md) {
            width: 100%;
          }

        }
      }

      > * {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }

  &.videoModal {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal {
      background-color: transparent;
      border-radius: 0;
      margin: 0;
    }

    .close-wrapper {
      margin: 0;
      position: absolute;
      top: 0;
    }
  }

  &.formModal {
    .iframe-form {
      height: calc(100vh - 64px);
    }

    .btn-medium {
      font-size: medium;
      height: 47px;
      margin: auto;
      min-width: 50%;
    }
  }
}
@media screen and (min-width: $md) {
  .modal-wrapper {
    .close-wrapper {
      height: 48px;
    }

    &.infoModal {
      .modal {
        margin: 16px auto;
        max-width: 500px !important;

        .modal-content {
          .btn {
            width: max-content;
          }

          .modal-button {
            .btn {
              display: inline-block;
              margin-bottom: 0;
              margin-right: 12px;
            }
          }
        }
      }
    }

    &.formModal {
      .iframe-form {
        height: calc(100vh - 80px);
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .modal-wrapper {
    .modal {
      margin: 32px auto;
    }

    &.formModal {
      .modal {
        max-width: 640px !important;
      }

      .iframe-form {
        height: calc(100vh - 122px);
      }
    }

    &.videoModal {
      .modal {
        margin-left: auto;
        margin-right: auto;
        max-width: 968px !important;
        width: 100%;
      }
    }
  }
}
