@import '../styles/_variables.scss';

.article-content-wrapper {
  .container {
    padding-top: 0;
    width: 100%;

    &.quote-wrapper {
      box-sizing: border-box;
      padding: 0;

      h2.title {
        margin-top: 20px;
      }
    }
  }
}

.bottom-navigation.container {
  padding: 0;
  margin: auto;

  .article-navigation {
    &.border-top {
      padding-top: 20px;
    }
  }
}

@media screen and (min-width: $md) {
  .article-content-wrapper {
    .container {
      max-width: 785px;
      padding: 64px 40px 0;
      width: calc(100% - 80px);
    }
  }

  .bottom-navigation {
    .content {
      padding: 0 40px;
      box-sizing: border-box;

      .article-navigation {
        &.border-top {
          padding-top: 15px;
        }
      }
    }
  }
}
